.base-section {
  @extend .x-padding;

  .body {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;

    .left {
      flex: 1 1 auto;
    }

    .right {
      flex: 1 1 auto;
    }
  }

  @include media-breakpoint-up(md) {
    .body {
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      .left {
        padding-right: 30px;
      }
      .left {
        flex: 0 1 30%;
      }

      .right {
        flex: 1 1 0%;
      }
    }
  }
}

#nav {
  & > div {
    @include make-container();
    @include media-breakpoint-up(md) {
      @include make-container-max-widths();
    }
  }
}

section.split {
  & > div {
    .row {
      div:first-child {
        article {
          background-color: $dune-color-white;
          height: 100%;
        }
      }

      div:last-child {
        aside {
          background-image: url("../" + $img-path + "/pattern-white.svg");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    & > div {
      background-position-x: 300px;
      background-image: url("../" + $img-path + "/pattern-white.svg");
      background-repeat: no-repeat;
      background-size: cover;

      .row {
        > div:first-child {
          padding-right: 0;
          article {
            padding-right: 15px;
          }
        }

        > div:last-child {
          padding-left: 0;

          aside {
            background-image: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    & > div {
      background-position-x: 400px;
    }
  }
  @include media-breakpoint-up(xl) {
    & > div {
      background-position-x: 600px;
    }
  }
}
section.split-lg {
  & > div {
    .row {
      div:first-child {
        article {
          background-color: $dune-color-white;
          height: 100%;
        }
      }

      div:last-child {
        aside {
          background-image: url("../" + $img-path + "/pattern-white.svg");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    & > div {
      background-position-x: 400px;
      background-image: url("../" + $img-path + "/pattern-white.svg");
      background-repeat: no-repeat;
      background-size: cover;

      .row {
        > div:first-child {
          padding-right: 0;
          article {
            padding-right: 15px;
          }
        }

        > div:last-child {
          padding-left: 0;

          aside {
            background-image: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    & > div {
      background-position-x: 600px;
    }
  }
}

.section2 {
  @extend .base-section;

  .body {
    .left {
      .link-country {
        margin-top: 40px;
        padding-right: 70px;
        display: flex;
        justify-content: space-around;
        padding-bottom: 8px;

        & > a {
          @include fontSize(20px);
          color: $dune-color-white;
        }
      }
    }

    .right {
      .carousel {
        max-width: 968px;
        margin-top: 20px;
        padding-top: 0;
      }
    }

    @include media-breakpoint-up(md) {
      .left {
        @include fontSize(40px);
        display: inline-block;

        .link-country {
          margin-top: 80px;
          flex-direction: column;
          justify-content: start;
          padding-bottom: 0;

          & > a {
            @include fontSize(40px);
            &:hover {
              color: $dune-color-corail;
            }
          }
        }
      }
      .right {
        .carousel {
          margin-top: 20px;
        }
      }
    }
  }
}

.section3 {
  @extend .base-section;
  position: relative;

  .body {
    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > div {
        flex: 1 1 40%;
        padding-top: 15px;
        padding-bottom: 15px;
        &.video {
          flex: 1 1 100%;
        }
        @include media-breakpoint-up(md) {
          &.video {
            flex: 1 1 40%;
          }
        }

        &:nth-child(odd) {
          padding-right: 15px;
        }

        &:nth-child(even) {
          padding-left: 15px;
        }

        & > span {
          display: block;
          height: 187px;
          border-radius: 5px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .body {
      .left {
        flex: 0 1 30%;
      }
      .right {
        justify-content: flex-end;

        & > div {
          &:nth-child(odd) {
            padding-right: 15px;

            & > span {
              margin-left: auto;
            }
          }

          &:nth-child(even) {
            padding-left: 15px;

            & > span {
              margin-right: auto;
            }
          }

          & > span {
            height: 278px;
            max-width: 463px;
            min-width: 150px;
          }
        }
      }
    }
  }
}

.section3.section-item-1 {
  .body {
    .right {
      & > div {
        flex: 0 1 100%;

        &:nth-child(odd) {
          padding-right: 0;
        }
      }

    }
  }
}

.section3.section-item-4 {
  .body {
    .right {
      & > div {
        flex: 0 1 25%;
      }
    }
  }
}

.section3.with-border-inside {
  .body {
    .right {
      & > div {
        &:nth-child(1), &:nth-child(2) {
          border-bottom: 1px solid $dune-color-corail;
        }

        &:nth-child(3), &:nth-child(4) {
          border-top: 1px solid $dune-color-corail;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .body {
      .right {
        & > div {
          &:nth-child(odd) {
            border-right: 1px solid $dune-color-corail;
          }

          &:nth-child(even) {
            border-left: 1px solid $dune-color-corail;
          }

          &:nth-child(1), &:nth-child(2) {
            border-bottom: none;
          }

          &:nth-child(3), &:nth-child(4) {
            border-top: none;
          }
        }
      }
    }
  }
}

.section3.with-btn-bottom {
  .body {
    .right {
      & > div:last-child {
        flex: 0 1 auto;
        border: none;
        display: flex;
        position: absolute;
        bottom: -20px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        & > a {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .body {
      .right {
        & > div:last-child {
          position: relative;
          padding-right: 0;

          & > a {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.section3.with-btn-inside {
  .body {
    .right {
      & > div {

        & > span {
          position: relative;

          a {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            @include fontSize($font-size-xxxs);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .body {
      .right {
        & > div {
          & > span {
            & > a {
              bottom: 20px;
              width: auto;
              font-size: initial;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .body {
      .right {
        & > div {
          & > span {
            & > a {
              bottom: 20px;
              width: auto;
              font-size: initial;
            }
          }
        }
      }
    }
  }
}

.section4 {
  @extend .section2;

  .body {
    .left {
      flex: 0;
    }

    .right {
      .carousel {
        max-width: 100%;
        margin-top: 20px;
        padding-top: 40px;

        .carousel-inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          text-align: center;

          & > div:not(.carousel-item) {
            padding: 15px;
            flex: 0 1 25%;
            width: 286px;

            & > img {
              width: 286px;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .body {
      .right {
        .carousel {
          padding-top: 0;

          .carousel-inner {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

section.section-break-lg {
  .body {
    display: block; /* FIX IE */
  }
  @include media-breakpoint-up(md) {
    .body {
      .left {
        padding-right: 0;
        flex-grow: 1;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .body {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      .left {
        padding-right: 30px;
        flex-grow: 0;
      }
    }
  }
}

/*section.newsletter {
  padding-left: 5%;
  padding-right: 5%;
  @include media-breakpoint-up(md) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 15%;
    padding-right: 15%;
  }

  .main-text {
    font-family: Dosis;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 35px;
    margin-bottom: 0;
    @include font-size($font-size-lg);

    .highlight {
      color: $dune-color-corail;
      @include font-size($font-size-base * 2);
    }
  }

  .sub-text {
    font-family: Dosis;
    font-weight: bold;
    @include font-size($font-size-xxs);
  }

  form {
    input:not([type="checkbox"]) {
      height: 74px;
      width: 100%;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        margin-right: 13px;
        flex: 1;
      }
    }

    button {
      height: 73px;
      padding: 0 50px;
    }
  }
}*/
