.accordion {
  .card {
    margin-bottom: 16px;
    border: none;
    .card-header {
      min-height: 58px;
      border: none;
      background-color: $dune-color-grey-light;
      padding-top: 0;
      padding-bottom: 0;
      h1,h2,h3,h4,h5,h6 {
        line-height: 20px;
        button {
          @include fontSize($font-size-lg);
          color: $dune-color-dark-blue;
          text-transform: uppercase;
          line-height: 40px;
          font-family: Raleway;
          font-weight: bold;

          &:after {
            content: "\f078";
            font-family: "Font Awesome";
            position: absolute;
            right: 20px;
            top: 10px;
          }
          &[aria-expanded="true"]:after {
            content: "\f054";
          }
        }
      }
    }
    .card-body {
      font-family: OpenSans;
      .card-text {
        @include fontSize($font-size-base);
        padding-left: 30px;
      }
    }
  }
}

.accordion.full {
  .card {
    .card-body {
      font-family: OpenSans;
      background-color: $dune-color-grey-light;
    }
  }
}