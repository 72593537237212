.select2-container--dune .select2-selection--single {
  height: calc(1.5em + 2rem + 2px) !important;
  .select2-selection__placeholder {
    color: $dune-color-dark-blue;
    text-transform: uppercase;
  }
  .select2-selection__arrow {
    position: absolute;
    top: 25%;
    right: 15px;
    width: 20px;
    &:after {
      content: "\f078";
      font-family: "Font Awesome";
    }
  }
  .select2-selection__rendered {
    line-height: calc(1.5em + 1.25rem);
    text-align: left;
    padding: 0;
  }

}
.select2-container--dune.select2-container--open {
  .select2-selection__arrow {
    position: absolute;
    top: 25%;
    right: 15px;
    width: 20px;
    &:after {
      content: "\f054";
      font-family: "Font Awesome";
    }
  }
}

.select2-search--dropdown .select2-search__field {
  border: 1px solid $dune-color-grey;
  @include border-radius(0.25rem);
}

.select2-results__message {
  color: #6c757d;
}

.select2-container--dune .select2-selection--multiple {
  min-height: calc(1.5em + 0.75rem + 2px) !important;
  .select2-search__field::placeholder {
    color: $dune-color-dark-blue;
    text-transform: uppercase;
  }
  .select2-selection__rendered {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
  }
  .select2-selection__choice {
    color: $dune-color-white;
    background-color: $dune-color-dark-blue;
    border: 1px solid #bdc6d0;
    @include border-radius(0.2rem);
    padding: 0;
    padding-right: 5px;
    cursor: pointer;
    float: left;
    margin-top: 0.3em;
    margin-right: 5px;
  }
  .select2-selection__choice__remove {
    color: $dune-color-white;
    font-weight: bold;
    margin-left: 3px;
    margin-right: 1px;
    padding-right: 3px;
    padding-left: 3px;
    float: left;
    &:hover {
      color: #343a40;
    }
  }
}

.select2-container {
  display: block;
  *:focus {
    outline: 0;
  }
}

.input-group .select2-container--dune {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.input-group-prepend ~ .select2-container--dune .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--dune {
  background-color: $dune-color-white;
  .select2-selection {
    border: 1px solid $dune-color-grey;
    @include border-radius(0.25rem);
    width: 100%;
    padding: 0.375rem 30px;
  }
  &.select2-container--focus {
    .select2-selection {
      border-color: #17a2b8;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    &.select2-container--open .select2-selection {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.select2-container--disabled {
    .select2-selection, &.select2-container--focus .select2-selection {
      background-color: #e9ecef;
      cursor: not-allowed;
      border-color: #ced4da;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .select2-search__field, &.select2-container--focus .select2-search__field {
      background-color: transparent;
      &::placeholder {

      }
    }
  }
}

select.is-invalid ~ .select2-container--dune .select2-selection, form.was-validated select:invalid ~ .select2-container--dune .select2-selection {
  border-color: #dc3545;
}

select.is-valid ~ .select2-container--dune .select2-selection, form.was-validated select:valid ~ .select2-container--dune .select2-selection {
  border-color: #28a745;
}

.select2-container--dune {
  .select2-dropdown {
    border-color: #ced4da;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    &.select2-dropdown--above {
      border-top: 1px solid #ced4da;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    .select2-results__option[aria-selected=true] {
      background-color: #e9ecef;
    }
  }
  .select2-results__option--highlighted {
    background-color: $dune-color-dark-blue;
    color: #f8f9fa;
    &.select2-results__option[aria-selected=true] {
      background-color: $dune-color-dark-blue;
      color: #f8f9fa;
    }
  }
  .select2-results__option[role=group] {
    padding: 0;
  }
  .select2-results > .select2-results__options {
    max-height: 15em;
    overflow-y: auto;
  }
  .select2-results__group {
    padding: 6px;
    display: list-item;
    color: #6c757d;
  }
  .select2-selection__clear {
    width: 1.2em;
    height: 1.2em;
    line-height: 1.15em;
    padding-left: 0.3em;
    margin-top: 0.5em;
    @include border-radius(100%);
    background-color: #6c757d;
    color: #f8f9fa;
    float: right;
    margin-right: 0.3em;
    &:hover {
      background-color: #343a40;
    }
  }
}