// ------------------------------
// - Color module
// ------------------------------

@each $key, $color in $colours {
  .color-#{$key} {
    color: $color !important;
  }

  .color-#{$key}--hover {
    &:hover {
      color: $color !important;
    }
  }

  .bg-color-#{$key} {
    background-color: $color !important;
  }

  .bg-color-#{$key}--hover {
    &:hover {
      background-color: $color !important;
    }
  }

  .border-color-#{$key} {
    border-color: $color !important;
  }

  .border-color-#{$key}--hover {
    &:hover {
      border-color: $color !important;
    }
  }
  .svg-#{$key} {
    fill: $color;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $key, $color in $colours {
    @include media-breakpoint-up($breakpoint) {
      .bg-color-#{$key}-#{$breakpoint} {
        background-color: $color !important;
      }
    }
  }
}