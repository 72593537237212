.testimonials {
    margin: 80px 0 50px;
    position: relative;
    background: $csecondaryLight;
    padding: 0;
    &::before,
    &::after {
        content: "";
        position: absolute;
        background-size: contain;
        background-repeat: repeat-x;
        width: 100%;
        z-index: -1;
    }
    &::before {
        background-image: url(../../../images/new/testimonials-top-green-separator.png);
        top: -36px;
        height: 66px;
    }
    &::after {
        background-image: url(../../../images/new/testimonials-bottom-green-separator.png);
        bottom: -22px;
        height: 60px;
    }
    &Title {
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        font-weight: 700;
        text-transform: none;
        margin-bottom: 70px;
        color: black;
        padding: 0 20px;
    }
    &Container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 0;
        min-height: 360px;
        &::before {
            content: "";
            background: url(../../../images/new/testimonials-quote.png) no-repeat;
            background-size: cover;
            width: 150px;
            height: 129px;
            top: 50%;
            left: 15%;
            transform: translateY(-50%);
            display: block;
            position: absolute;
        }
        &::after {
            content: "";
            background: url(../../../images/new/testimonials-wave.png) no-repeat;
            background-size: contain;
            width: 428px;
            height: 281.5px;
            right: 0;
            bottom: 20px;
            display: block;
            position: absolute;
        }
    }
    &Slider {
        padding: 0 20px;
        overflow: hidden;
        width: 100%;
        visibility: hidden;
        &.slick-initialized {
            visibility: visible;
        }
        .slick-dots {
            bottom: 0;
            li {
                width: 6px;
                height: 6px;
                margin: 0 4px;
                button {
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                    background: $csecondary;
                    opacity: 0.3;
                    &:before {
                        display: none;
                    }
                }
                &.slick-active {
                    width: 21px;
                    button {
                        opacity: 1;
                    }
                }
            }
        }
        &Item {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 100px;
            &Name {
                font-weight: 700;
                font-size: 16px;
                color: $csecondary;
            }
            &Travel {
                color: $cgrey;
                font-size: 12px;
                margin-bottom: 20px;
            }
            &Note {
                display: flex;
                align-items: center;
                column-gap: 10px;
                margin-bottom: 20px;
                &Date {
                    color: $cgrey;
                    font-size: 12px;
                }
                &Icons {
                    svg {
                        fill: #DDDDDD;
                        &.active {
                            fill: #F6B928;
                        }
                    }
                }
            }
            &Text {
                max-width: 700px;
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 1199px) {
    .testimonials::before,
    .testimonials::after {
        background-size: cover;
    }
}

@media (max-width: 991px) {
    .testimonials {
        &Title {
            margin-bottom: 50px;
        }
        &Slider {
            &Item {
                padding-bottom: 50px;
            }
        }
        &Container {
            &::before {
                left: 5%;
            }
            &::after {
                width: 338px;
                bottom: -30px;
            }
        }
    }
}
@media (max-width: 575px) {
    .testimonials {
        &Container {
            &::after {
                width: 200px;
                bottom: -130px;
            }
        }
    }
}