.map {
  position: relative;
  background-color: $dune-color-grey-light;
  width: 100%;
  margin: auto;
  padding: 40px;
  img, svg {
    color: $dune-color-corail;
    fill: $dune-color-corail;
  }
  > svg {
    height: 536px;
  }

  .pin-map {
    position: absolute;
    width: 30px;
    height: auto;
    .card-map {
      display: none;
      position: absolute;
      min-width: 150px;
      text-align: center;
      padding: 10px;
      z-index:10;
      img, svg {
        width: 70px;
        margin: auto;
        height: auto;
      }
    }
    &:hover {
      .card-map {
        display: flex;
      }
    }

  }
}
