.sub-nav {
  background-color: $dune-color-dark-blue;
  text-transform: uppercase;
  position: relative;
  @include media-breakpoint-up(md) {
    background-color: transparent;
  }

  .nav-toggler {
    color: $dune-color-white;
    height: 60px;
    background-color: transparent;
    border: none;

    .svg-inline--fa {
      top: 20px;
      right: 15px;
    }
    &:focus {
      outline: none;
    }
  }

  .nav {
    font-size: $font-size-xs;

    .nav-item {
      &:last-child {
        padding-bottom: 1rem;
      }
      @include media-breakpoint-up(md) {
        a {
          padding: 0;
        }
        &:first-child {
          .nav-link {
            padding-left: 0;
          }
        }
        &:last-child {
          padding-bottom: inherit;
        }
        & + .nav-item {
          padding-left: 1rem;
          & > a {
            &:before {
              padding-right: 1rem;
              color: $dune-color-dark-blue;
              content: "|";
            }
          }
        }
      }

      .nav-link {
        color: $dune-color-white;
        @include media-breakpoint-up(md) {
          color: $dune-color-dark-blue;
        }

        &.active {
          background-color: transparent;
        }
      }

      &.show {
        .nav-link {
          background-color: transparent;
        }
      }

      .dropdown-menu {
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
          padding: 1rem;
          width: auto;
          flex-direction: row;
          background-color: $dune-color-white;
        }
        @include media-breakpoint-down(sm) {
          position: relative !important;
          transform: none !important;
          background-color: transparent;
        }

        .dropdown-item {
          color: $dune-color-white;
          @include fontSize($font-size-base);
          @include media-breakpoint-up(md) {
            color: $dune-color-dark-blue;
          }

          &:hover {
            background-color: transparent;
            color: $dune-color-grey-light;
            @include media-breakpoint-up(md) {
              color: $dune-color-blue;
            }
          }
        }
      }
    }
  }
}

/*========================================
Nav Pills
========================================*/

ul.nav.nav-pills {
  border: 0 solid $dune-color-grey;
  border-width: 0 0 1px 1px;
  > li {
    flex: 1 0 auto;
    border: 0 solid $dune-color-grey;
    border-width: 1px 1px 0 0;
    text-align: center;
    text-transform: uppercase;
    .nav-link {
      color: $dune-color-dark-blue;
      border-radius: 0;
      height: 100%;
      @include fontSize($font-size-xs);
      padding: 13px 4px;
      &.active {
        background-color: $dune-color-corail;
        color: $dune-color-white;
      }
    }
  }
}

/*========================================
End Nav Pills
========================================*/