.datepicker {
  padding-left: 50px;
  padding-right: 50px;
  color: $dune-color-dark-blue;
  @include fontSize($font-size-xxs);
  table tr td.active {
    background-image: linear-gradient(to bottom, $dune-color-corail, $dune-color-corail);
    text-shadow: none;
  }
  .prev, .next, .datepicker-switch {
    color: $dune-color-grey;
  }
  .dow {
    color: $dune-color-dark-grey;
  }
}