body#home {
  /*========================================
  Main Carousel
  ========================================*/
  #header {
    .carousel {
      padding-top: 0;
      padding-bottom: 0;

      .carousel-item {
        padding-top: 0;
        height: 560px;

        img.img-slide {
          visibility: hidden;
          height: 400px;
        }
      }

      .carousel-caption {
        bottom: auto;
        width: 100%;
        left: 0;
        top: 0;
        padding-top: 40px;
        height: 100%;

        .carousel-title {
          margin-bottom: 10px;
        }

        .carousel-sub-caption {
          height: 160px;
          width: 100%;
          margin-top: -10px;
          bottom: 0;
          position: absolute;
          background-color: $dune-color-dark-blue;

          img, svg {
            height: 32px;
          }
        }
      }

      .dune-carousel-control-prev {
        right: auto;
        left: 30px;
      }

      .dune-carousel-control-next {
        right: 30px;
      }

      .dune-carousel-control-prev, .dune-carousel-control-next {
        display: block !important;
        position: absolute;
        top: 36%;
        color: $dune-color-white;
        line-height: 37px;
        border: 1px solid $dune-color-white;
        @include border-radius(50%);
        text-align: center;
        height: 40px;
        width: 40px;
        z-index: 15;
      }
    }

    @include media-breakpoint-up(md) {
      .carousel {

        .dune-carousel-control-prev, .dune-carousel-control-next {
          top: 50%;
        }

        .dune-carousel-control-prev {
          right: auto;
          left: 60px;
        }

        .dune-carousel-control-next {
          right: 60px;
        }

        .carousel-item {
          height: 700px;

          img.img-slide {
            height: 700px;
          }
        }

        .carousel-caption {
          top: 100px;

          .carousel-sub-caption {
            width: 390px;
            margin-top: auto;
            position: relative;
          }
        }
      }
    }
  }

  /*========================================
  End Main Carousel
  ========================================*/

  /*========================================
  Pictos
  ========================================*/
  #pictos {
    .picto {
      img, svg {
        height: 78px;
        width: 88px;
      }

      > div {
        margin-top: 1rem;
        @include fontSize($font-size-xs)
      }
    }

    @include media-breakpoint-up(md) {
      .picto {
        > div {
          @include fontSize($font-size-base)
        }
      }
    }
  }

  /*========================================
  End Pictos
  ========================================*/

  /*========================================
  Offers
  ========================================*/
  #offers {
  }

  /*========================================
  End Offers
  ========================================*/

  /*========================================
  Travel
  ========================================*/

  #travel {
    .divider- * {
      margin: 0 0 40px 0;
    }
  }

  /*========================================
  End Travel
  ========================================*/

  /*========================================
  Team
  ========================================*/

  #team {

  }

  /*========================================
  End Team
  ========================================*/

  /*========================================
  Map
  ========================================*/

  #map {
    background-color: $dune-color-dark-blue;
    @include media-breakpoint-up(lg) {
      height: 650px;
      margin-bottom: 250px;
    }
  }

  /*========================================
  End Map
  ========================================*/

  /*========================================
  Newsletter
  ========================================*/

  #newsletter {

  }

  /*========================================
  End Newsletter
  ========================================*/

  /*========================================
  Social
  ========================================*/

  #social {
    background-color: $dune-color-grey-light;

    .insta {
      max-width: 640px;
      @include media-breakpoint-up(md) {
        flex: 1 0 auto;
      }

      div {
        position: relative;
        &::before {
          font-family: "Font Awesome";
          content: '\f16d';
          color: $dune-color-white;
          position: absolute;
          top: 20px;
          left: 30px;
        }
      }
    }

    .icon-social {
      margin: auto;
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    .card {
      margin-bottom: 20px;

      .card-text {
        line-height: 20px;
        margin-top: 20px;
      }
    }
  }

  /*========================================
  End Social
  ========================================*/
}
