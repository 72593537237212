form {
  & > div {
    display: flex;
  }

  .form-control {
    padding: 0.375rem 30px;
    color: $dune-color-dark-blue;
    border: 1px solid $dune-color-grey;
    @include border-radius(4px);
    font-size: $font-size-xs;
    max-width: 530px;
    height: 64px;
    text-align: left;

    &.full-width {
      max-width: 100%;
    }
  }

  .form-control::placeholder, button.form-control {
    text-transform: uppercase;
    color: $dune-color-dark-blue;
  }

  .form-group.counter {
    > div {
      display: flex;
      padding-bottom: 10px;

      label {
        justify-self: flex-start;
        margin-right: auto;
      }

      input {
        background-color: transparent;
        border: 0;
        width: 30px;
        display: block;
        float: left;
        text-align: center;
      }

      .counter-button {
        width: 30px;
        height: 30px;
        text-align: center;
        background: transparent;

        i, svg {
          height: 100%;
        }

        &.dec {
          border: 2px solid $dune-color-grey;
          color: $dune-color-grey;
          margin-left: 10px;
        }

        &.inc {
          border: 2px solid $dune-color-corail;
          color: $dune-color-corail;
        }
      }
    }
  }

  textarea.form-control {
    height: 270px;

    &.fit {
      height: 120px;
    }
  }

  button[type=submit]:not([class^="btn-dune-"]) {
    padding: 0.375rem 2rem;
    background-color: $dune-color-white;
    border: 2px solid $dune-color-corail;
    color: $dune-color-corail;
    @include border-radius(4px);
    text-transform: uppercase;
  }


  input[type=checkbox].check-dune, input[type=radio].check-dune {
    position: absolute;
    opacity: 0;

    &:checked + label svg path {
      stroke-dashoffset: 0;
    }

    &:focus + label {
      transform: scale(1.03);
    }
  }

  .form-group {

    .input-group {
      > img, > svg, > i {
        position: absolute;
        width: 15px;
        top: 23px;
        z-index: 1;
        left: 15px;
        color: $dune-color-grey;
      }

      .form-control, .select2-container--dune .select2-selection {
        padding-left: 45px;
      }
    }
  }

  .check-dune + label {
    display: block;
    border: 1px solid #333;
    width: 17px;
    height: 17px;
    float: left;
    @include border-radius(5px);
    cursor: pointer;
    transition: all .2s ease;

    &:active {
      transform: scale(1.05);
      @include border-radius(10px);
    }

    svg {
      pointer-events: none;
      overflow: visible;

      path {
        fill: none;
        stroke: $dune-color-corail;
        stroke-width: 8px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 100;
        stroke-dashoffset: 101;
        transition: all 350ms cubic-bezier(1, 0, .37, .91);
      }
    }
  }


  @each $classe in $classes {
    .check-dune-square {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 17px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .checkmark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include fontSize($font-size-xxs);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $dune-color-grey-light;
        @include border-radius(5px);
        text-transform: uppercase;
        text-align: center;
        padding: 10px;

        img, svg {
          margin-bottom: 8px;
          height: 20px;
        }
        p {
          font-family: 'Dosis';
          font-weight: 400;
          @include fontSize($font-size-xxs);
          line-height: 10px;
          margin: 0;
        }
      }

      input[type="checkbox"], input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
          background-color: $dune-color-corail;
          color: $dune-color-white;

          img, svg {
            fill: $dune-color-white !important;
          }
        }
      }
    }
    .check-dune-square-#{$classe} {
      @extend .check-dune-square;
      @if ($classe == 'sm') {
        height: 80px;
        width: 83px;
        margin: 5px;
      } @else if ($classe == 'md') {
        height: 120px;
        width: 147px;
        margin: 9px;
      } @else if ($classe == 'lg') {
        height: 120px;
        width: 147px;
      }
    }
  }

  &.immersion {
    & > div {
      &:nth-child(1n+2) {
        margin-top: 30px;
      }

      &:last-child {
        margin-top: 30px;
        justify-content: center;
      }

      flex-direction: column;

      .choices {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        justify-content: center;
      }

      .title {
        display: flex;
        @include fontSize($font-size-xlg);
        color: $dune-color-corail;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }

    @include media-breakpoint-up(md) {
      & > div {
        &:nth-child(1n+2) {
          margin-top: 30px;
        }

        &:last-child {
          margin-top: 30px;
        }

        flex-direction: row;

        .choices {
          padding-left: 100px;
          justify-content: start;
        }

        .title {
          flex: 0 1 28%;
          padding-right: 35px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      & > div {
        .choices {
          padding-right: 15%;
        }

        .title {
        }
      }
    }
  }
}
#step1_extras ~ .select2 {
  &:after {
    content: '\f002';
    font-family: "Font Awesome";
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 14px;
    color: $dune-color-corail;
  }
}
#modalSearch .modal-content {
  &:after {
    content: '\f002';
    font-family: "Font Awesome";
    color: $dune-color-white;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 2px;
  }
}
