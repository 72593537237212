nav.pagination-dune {
  ul {
    justify-content: center;
    > li {
      a.page-link {
        color: $dune-color-dark-grey;
        border: 0;
        &:not([href]):not([tabindex]):focus {
          color: $dune-color-corail;
        }
      }
      &.active {
        a.page-link {
          color: $dune-color-corail;
          background-color: transparent;
          border: 0;
        }
      }
    }
  }
}