.banner {
  background-color: $dune-color-grey-light;
  padding: 21px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;

  & > .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px 15px;

    img, svg {
      height: 32px;
    }

    p {
      font-family: "Dosis";
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: $font-size-xxxs;

      span {
        color: $dune-color-corail;
      }
    }

    &.price {
      text-transform: uppercase;
      @include fontSize($font-size-base);

      .line1 {
        color: $dune-color-corail;
        @include fontSize($font-size-base);
        b {
          @include fontSize(40px);
        }
      }

      .line2 {
        @include fontSize($font-size-base);
        font-weight: 300;
        padding-bottom: 10px;

        .crossed {
          position: relative;
          color: $dune-color-dark-blue;

          &:before {
            content: " ";
            display: block;
            width: 100%;
            border-top: 2px solid #050330;
            height: 12px;
            position: absolute;
            bottom: -5px;
            left: 0;
            transform: rotate(-35deg);
          }
        }
      }

      .line3 {
        background-color: $dune-color-corail;
        color: $dune-color-white;
        @include border-radius(15px);
        padding-left: 15px;
        padding-right: 15px;
        text-transform: lowercase;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;

        b {
          font-size: $font-size-xlg;
        }
      }
    }

    &.item-xl {

      img, svg {
        height: 42px;
      }

      p {
        color: $dune-color-corail;
        @include fontSize(35px);

        span {
          @include fontSize($font-size-base);
        }
      }
    }
  }
}