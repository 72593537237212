#footer {
  background-color: $dune-color-dark-blue;
  color: $dune-color-white;
  padding: 50px 0;
  ul {
    padding-left: 0;
  }

  ul.footer-list > li.footer-header {
    margin-bottom: 29px;
    font-size: $font-size-xs;
  }

  form {
    position: relative;
    input[type=text] {
      margin-right: 0 !important;
      padding: 0.375rem 5.2rem 0.375rem 0.75rem;
      width: 100%;
      color: $dune-color-dark-blue;
      font-size: $font-size-xs;
      height: 38px;

      &::placeholder {
        font-size: $font-size-xs;
        color: $dune-color-dark-blue;
      }
    }

    button[type=submit] {
      position: absolute;
      height: 38px;
      font-size: $font-size-xxxs;
      top: 0;
      right: 0;
      background-color: $dune-color-white;
      border: 2px solid $dune-color-corail;
      color: $dune-color-corail;
    }
  }

  ul.footer-list.destinations {
    li:not(.footer-header) {
      float: left;
      &::after {
        margin: 0 10px;
        content: "|";
        font-weight: 100;
      }
      @include media-breakpoint-up(md) {
        float: none;
        &::after {
          margin: 0;
          content: "";
          font-weight: inherit;
        }
      }
    }
  }

  ul.footer-list > li {
    list-style: none;
    overflow: hidden;
    line-height: 21px;

    & > a {
      font-size: $font-size-xxs;
      font-weight: 100;
      color: $dune-color-white;
    }
  }

  .logo-footer {
    height: 66px;
  }
  .back-top {
    display: none;
    position: fixed;
    height: 60px;
    width: 60px;
    background-color: white;
    z-index: 100;
    @include border-radius(8px);
    bottom: 90px;
    right: 30px;
    text-align: center;
    box-shadow: 0 0 15px 2px rgba(0,0,0,0.15);
    i,svg,img {
      height: 35px;
      margin-top: 10px;
      stroke: coral;
      stroke-width: 5px;
    }
  }
}

