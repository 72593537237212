ul.filters-sort {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  flex-direction: column;
  > li {
    line-height: 16px;
    position: relative;
    flex: 1 1 0%;
    border-bottom: 1px solid $dune-color-grey;
    border-right: 1px solid $dune-color-grey;
    border-left: 1px solid $dune-color-grey;
    text-transform: uppercase;
    text-align: center;
    &:first-child {
      border-top: 1px solid $dune-color-grey;
    }

    i, svg {
      color: $dune-color-corail;
      @include fontSize($font-size-base);
      margin-right: 5px;
    }

    a {
      color: $dune-color-dark-blue;
      @include fontSize($font-size-base);
      padding: 13px 28px;
      display: block;
      &:after {
        font-family: "Font Awesome";
        content: '\f0dc';
        position: absolute;
        right: 10px;
      }
    }

    &.active {
      background-color: $dune-color-corail;
      a {
        color: $dune-color-white;
        &.asc:after {
          content: "\f0de";
        }
        &.desc:after {
          content: "\f0dd";
        }
      }
      i, svg {
        color: $dune-color-white;
      }
    }
  }

  @include media-breakpoint-up(md) {
      flex-direction: row;
    > li {
      border-left:0;
      border-top:1px solid $dune-color-grey;
      &:first-child {
        border-left: 1px solid $dune-color-grey;
      }
    }
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  flex-direction: column;
  > li {
    flex: 1 1 0%;
    > a {
      padding: 0.5rem 1.5rem 0.5rem 2.2rem;
      text-align: left;
      position: relative;
      border: 1px solid $dune-color-grey-light;
      font-size: 16px;
      line-height: 16px;
      color: $dune-color-dark-blue;
      text-transform: uppercase;
      @include fontSize($font-size-xxs);
      &:after {
        content: "\f078";
        font-family: "Font Awesome";
        position: absolute;
        right: 5px;
        top: 30%;
        border: 0;
      }
      img, svg {
        position: absolute;
        width: 15px;
        max-height: 17px;
        z-index: 1;
        left: 15px;
      }
    }
    .dropdown-menu {
      padding: 5px 10px;
      ul {
        @include fontSize($font-size-xxs);
        > li {
         a {
           @include fontSize($font-size-xxs);
         }
        }
      }
      &.filter-price {
        p {
          @include fontSize($font-size-xxs);
        }
        input {
          max-width: 100px;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: nowrap;
    > li {
    }
  }
}