@each $key, $divider in $dividers {
  .divider-#{$key} {
    display: block;
    background-image: url("../" + $img-path + "/pictos/vague.svg");
    background-repeat: no-repeat;
    background-origin: content-box;
    height: 25px;
    width: 90px;
    margin: (30px * $divider) auto;
    @include media-breakpoint-up(md) {
      margin: (58px * $divider) auto;
    }
  }
  .divider-#{$key}-left {
    @extend .divider-#{$key};
    margin-left: 0;
    margin-right: auto;
  }
  .divider-#{$key}-right {
    @extend .divider-#{$key};
    margin-left: auto;
    margin-right: 0;
  }
  .divider-#{$key}-left-white {
    @extend .divider-#{$key};
    background-image: url("../" + $img-path + "/pictos/vague-white.svg");
    margin-left: 0;
    margin-right: auto;
  }
  .divider-#{$key}-right-white {
    @extend .divider-#{$key};
    background-image: url("../" + $img-path + "/pictos/vague-white.svg");
    margin-left: auto;
    margin-right: 0;
  }
}
.vague {
  background-position: center;
  background-image: url("../" + $img-path + "/pictos/vague.svg");
  background-repeat: no-repeat;
  background-origin: content-box;
  display: inline-block;
  height: 25px;
  width: 90px;
}