.btn-dune {
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  white-space: normal;
  text-align: center;
  span {
    height: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  img, svg {
    display: inline-block;
    height: 17px;
    margin-right: 15px;
    width: 15px;
  }
}
@each $classe in $classes {
  .btn-dune-full-#{$classe} {
    @extend .btn-dune;
    background-color: $dune-color-corail;
    color: $dune-color-white!important;
    border: none;
    @include border-radius(5px);
    @include button($classe);
    &:hover {
      color: $dune-color-white;
      text-decoration: none;
    }
  }

  .btn-dune-#{$classe} {
    @extend .btn-dune;
    background-color: $dune-color-white;
    color: $dune-color-corail!important;
    border: 2px solid $dune-color-corail;
    @include button($classe);
    @include border-radius(5px);
    &:hover {
      color: $dune-color-corail;
      text-decoration: none;
    }
  }

  .btn-dune-light-#{$classe} {
    @extend .btn-dune;
    color: $dune-color-dark-blue!important;
    appearance: none;
    border: 0;
    text-align: left;
    @include button($classe);
    &:hover {
      color: $dune-color-dark-blue;
      text-decoration: none;
    }
    &:after {
      font-family: "Font Awesome";
      content: "\f061";
      color: $dune-color-corail;
      margin-left: 15px;
    }
    &:focus {
      outline: none;
    }
  }
}

.btn-dune-border-white {
  border: 2px solid #ffffff;
}

.btn-dune-header {
  @extend .btn-dune;
  img, svg {
    height: 20px;
    width: 21px;
  }
  background-color: $dune-color-white;
  color: $dune-color-corail!important;
  border: 2px solid $dune-color-corail;
  @include button(md);
  padding: 4px 15px 4px 5px;
  @include border-radius(5px);
  &:hover {
    color: $dune-color-corail;
    text-decoration: none;
  }
}

.btn-collapse-50 {
  font-family: Dosis;
  width: 50%;
  text-align: center;
  margin: 11px auto;
  color: $dune-color-white;
  background-color: $dune-color-corail;
  border: 1px solid $dune-color-corail;
  @include border-radius(5px);
  text-transform: uppercase;
  float: left;
  @include fontSize($font-size-xs);
  letter-spacing: 1.5px;
  &[aria-expanded="false"] {
    color: $dune-color-corail;
    background-color: $dune-color-white;
    border: 1px solid $dune-color-corail;
  }
  &:focus {
    outline: none;
  }
}
.btn-dune-group {
  button {
    border-radius: 0;
  }
  & button:first-child {
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
  }
  & button:last-child {
    border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
  }
}

.btn-overlap-bottom {
  position: absolute;
  left: 0;
  bottom: -30px;
}

.btn-filter {
  @extend .btn-dune;
  background-color: $dune-color-white;
  color: $dune-color-corail!important;
  border: 2px solid $dune-color-corail;
  @include fontSize($font-size-xxxs);
  padding: 5px 5px;
  line-height: 10px;
  @include border-radius(5px);
  &:hover {
    color: $dune-color-corail;
    text-decoration: none;
  }
  img, svg {
    height: 10px;
  }
}
.btn-filter-dark-blue {
  @extend .btn-filter;
  color: $dune-color-dark-blue!important;
  border: 2px solid $dune-color-dark-blue;
  &:hover {
    color: $dune-color-dark-blue;
  }
}
.btn-filter-full-dark-blue {
  @extend .btn-filter;
  color: $dune-color-white!important;
  border: 2px solid $dune-color-dark-blue;
  background-color: $dune-color-dark-blue;
}