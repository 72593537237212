// stylelint-disable declaration-no-important

// Common values
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $position in $positions {
    @include media-breakpoint-up($breakpoint) {
      .position-#{$breakpoint}-#{$position} {
        position: $position !important;
      }
    }
  }
}
