#header {
  .header-img {
    height: 450px;
    width: 100%;
    background-repeat:no-repeat;
    background-size: cover;
    background-position:center;
  }

  .header-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;

    .header-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      text-align: center;
      align-content: center;

      .header-title {
        margin-bottom: 10px;

        &.full-white {
          .sub-title-top {
            @include fontSize(18px);
            text-transform: uppercase;
          }

          h1 {
            color: $dune-color-white;
          }

          .sub-title-bottom {
            @include fontSize(16px);
            text-transform: uppercase;
            font-weight: 100;
            line-height: 16px;
          }
        }
      }
    }

    .header-btn-bottom {
      display: flex;
      flex-direction: column;

      & > a {
        margin-right: auto;
        margin-left: auto;
        margin-top: auto;
      }
    }

    .header-sub-caption {
      width: 100%;
    }

    .diver {
      width: 108px;
      height: 108px;
      background-color: $dune-color-corail;
      font-size: 25px;
      @include border-radius(100%);
      line-height: 68px;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      position: absolute;
      bottom: -54px;
      left: 50%;
      transform: translate(-50%, 0);

      img, svg {
        text-align: center;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.header-light {
    .header-img {
      height: 450px;
      width: 100%;
    }

    h1 {
      @include fontSize(40px);
      color: $dune-color-white;
    }

    [class^="btn-dune-full"] {
      border: 1px solid $dune-color-white;
    }
  }

  @include media-breakpoint-up(md) {
    .header-img {
      height: 700px;
    }
    .header-caption {
      display: block;

      .header-body {
        height: 100%;

        .header-title {
          &.full-white {
            .sub-title-top {
              @include fontSize(36px);
            }

            h1 {
              @include fontSize(40px);
              line-height: 40px;
            }

            .sub-title-bottom {
              @include fontSize(36px);
              line-height: 36px;
            }
          }
        }
      }

      .header-btn-bottom {
        @include make-container();
        @include make-container-max-widths();
        flex-direction: row;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 30px;
        margin: auto;
            flex: 1 1 0%;

        & > a {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    &.header-light {
      .header-img {
        height: 380px;
        width: 100%;
      }
    }
  }
}