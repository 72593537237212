#cookie_consent {
  background: #000;
  position: fixed;
  bottom: 0;
  float: left;
  z-index: 100000;
  width: 100%;
  padding: 30px 0;
  /*filter: alpha(opacity=70);
  opacity: 0.7;*/
}
#cookie_consent p {
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0 0 10px 0;
  /*filter: alpha(opacity=100);
  opacity: 1;*/
}
#cookie_consent a.accept_cookies {
  margin: 0 5px;
  padding: 5px;
  color: $dune-color-white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: $dune-color-corail;
}
#cookie_consent a.more_info {
  margin: 0 5px;
  padding: 5px;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: #ccc;
}