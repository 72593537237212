h1, h2 {
  text-transform: uppercase;
  color: $dune-color-corail;
  @include fontSize(30px);
  line-height: 40px;
  @include media-breakpoint-up(md) {
    @include fontSize(65px);
    line-height: 65px;
  }
}

h2.light {
  text-transform: uppercase;
  color: $dune-color-dark-blue;
  @include fontSize(30px);
  line-height: 40px;
  @include media-breakpoint-up(md) {
    @include fontSize(40px);
  }
}

h3 {
  text-transform: uppercase;
  color: $dune-color-dark-blue;
  @include fontSize($font-size-base);

  &.strong {
    @include fontSize(30px);
    @include media-breakpoint-up(md) {
      @include fontSize(40px);
    }
  }
}

h4 {
  //opacity: 0.5;
  font-size: $font-size-lg;

  &.strong {
    @include fontSize(30px);
    text-transform: uppercase;
  }
}

h2.bubbles {
  position: relative;
  display: inline-block;
  &::after {
    width: 25px;
    height: 20px;
    top: -15px;
    position: absolute;
    left: 70%;
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    background-image: url("../" + $img-path + "/pictos/bubbles.svg");
  }
  @include media-breakpoint-up(md) {
    &::after {
      height: 40px;
      top: -50px;
    }
  }
}

.sub-title {
  text-transform: uppercase;
  color: $dune-color-white;
  position: relative;
  top: -20px;
  margin-top: 20px;
  font-weight: 600;
  @include fontSize(18px);
  line-height: 18px;
  @include media-breakpoint-up(md) {
    @include fontSize(40px);
    line-height: 40px;
  }
}


@each $key, $font-size in $font-sizes {
  .title-#{$key} {
    text-transform: uppercase;
    @include fontSize($font-size);
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.title-base {
  text-transform: uppercase;
  @include fontSize($font-size-base);
  margin-top: 30px;
  margin-bottom: 30px;
}

.title-30 {
  text-transform: uppercase;
  @include fontSize(30px);
  margin-top: 30px;
  margin-bottom: 30px;
}

.title-40 {
  text-transform: uppercase;
  @include fontSize(40px);
  margin-top: 30px;
  margin-bottom: 30px;
}


.style-h1 {
  font-family: Dosis;
  @extend h1;
}
.style-h2 {
  @extend h2;
}
.style-h3 {
  @extend h3;
}
.style-h4 {
  text-transform: capitalize;
  @extend h4;
}