.carousel.dune-carousel {
  padding-bottom: 60px;
  padding-top: 40px;

  .dune-carousel-control-prev {
    right: 50px;
  }

  .dune-carousel-control-next {
    right: 0;
  }

  .dune-carousel-control-prev, .dune-carousel-control-next {
    position: absolute;
    top: 29px;
    color: $dune-color-dark-grey;
  }

  & > .carousel-inner {
    display: flex;
    flex-wrap: wrap;

    & > .carousel-item {
      padding: 40px 0 0 0;

      &.padding-bottom-down-md {
        padding-bottom: 70px;
      }

      .card {
        margin: auto;
      }
    }
  }

  h4 {
    position: absolute;
    top: -18px;
    z-index: 1000;
    max-width: 80%;
    color: $dune-color-grey;
    font-weight: 600;
  }

  .carousel-indicators {
    & > li {
      background-color: $dune-color-white;
      border: 1px solid $dune-color-dark-blue;
      @include border-radius(50%);
      height: 10px;
      width: 10px;

      &.active {
        background-color: $dune-color-corail;
        border: none;
        height: 12px;
        width: 12px;
      }
    }
  }

  &.carousel-no-padding-top {
    padding-top: 0 !important;

    .carousel-item {
      padding-top: 0;
    }

    .dune-carousel-control-prev, .dune-carousel-control-next {
      top: -55px;
    }
  }

  @include media-breakpoint-up(md) {
    &:not(.carousel-with-indicators) {
      padding-bottom: 0;
    }
    padding-top: 0;
    h4 {
      position: relative;
      z-index: inherit;
      max-width: 100%;
      top: 0;

    }
    .carousel-indicators, .dune-carousel-control-prev:not(.light), .dune-carousel-control-next:not(.light) {
      display: none;
    }
    &.carousel-no-padding-top {
      padding-top: 0;

      .carousel-item {
        padding-top: 0;

        &.padding-bottom-down-md {
          padding-bottom: 0;
        }
      }
    }
  }
}

.dune-carousel.carousel-responsive[data-slides] {
  @include media-breakpoint-up(md) {
    .carousel-item {
      .card {
        margin: 0 15px !important;

        &:first-child {
          margin-left: 60px !important;
        }

        &:last-child {
          margin-right: 60px !important;
        }

        float: left;
        height: 100%;
      }
    }
    &[data-slides="1"] {
      .carousel-item {
        .card {
          width: calc(100% - 120px);
        }
      }
    }

    &[data-slides="2"] {
      .carousel-item {
        .card {
          width: calc(50% - 75px);
        }
      }
    }

    &[data-slides="3"] {
      .carousel-item {
        .card {
          width: calc(33.33% - 60px);
        }
      }
    }

    &[data-slides="4"] {
      .carousel-item {
        .card {
          width: calc(25% - 52.5px);
        }
      }
    }

    .dune-carousel-control-prev:not(.light) {
      right: auto;
      left: 0;
    }

    .dune-carousel-control-next:not(.light) {
      right: 0;
    }

    .carousel-indicators {
      display: flex;
    }
    .dune-carousel-control-prev:not(.light), .dune-carousel-control-next:not(.light) {
      display: block;
      position: absolute;
      top: 50%;
      color: $dune-color-dark-grey;
      line-height: 37px;
      border: 1px solid $dune-color-dark-grey;
      @include border-radius(50%);
      text-align: center;
      height: 40px;
      width: 40px;
    }
  }
}

.dune-carousel.carousel-white {
  .dune-carousel-control-prev, .dune-carousel-control-next {
    color: $dune-color-white;
  }

  .carousel-indicators > li {
    background-color: $dune-color-dark-blue;
    border: 1px solid $dune-color-white;

    &.active {
      background-color: $dune-color-white;
    }
  }
}

.dune-carousel.carousel-table {
  .carousel-item {
    overflow: scroll;
    padding-top: 0 !important;
  }

  .dune-carousel-control-prev {
    left: 20%;
    right: auto;
    top: 0 !important;
  }

  .dune-carousel-control-next {
    right: 20%;
    top: 0 !important;
  }

  @include media-breakpoint-up(md) {
    .dune-carousel-control-prev {
      left: 28%;
    }

    .dune-carousel-control-next {
      right: 28%;
    }
  }
}

.carousel-cabin {
  .carousel-indicators {
    display: flex;
  }

  .carousel-inner > div {
    &.carousel-item-left,
    &.carousel-item-right {
      width: 100%;
      height: 100%;
    }

    margin: 0 !important;
    padding: 0 !important;
  }
}

.carousel-offer-home {
  top: -60px;
  & > .carousel-item h3 {
    position: relative;
  }
  @include media-breakpoint-up(md) {
    top: 0;
  }
}

//--------------------------------
// Carousel Embedded
//--------------------------------
/**
  * style variables
*/
/**
  * Control & indicator mixin
*/
.carousel-embedded {
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  /**
    * Where the magic happens
    */
  /**
      * Control element - right/left arrows
    */
  /**
      * Element for holding slide indicators
    */
  /**
      * Indicator for indicating active slide
    */
  /**
      * Create rules for when slides are contained within a track
    */
}
.carousel__controls,
.carousel__activator {
  display: none;
}

.carousel__indicators {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
.carousel__indicator {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  border: 1px solid $dune-color-white;
  display: inline-block;
  background-color: transparent;
  z-index: 2;
  cursor: pointer;
  margin: 0 2.5px 0 2.5px;
}
.carousel__indicator:hover {
  opacity: 0.75;
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__track {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__slide:nth-of-type(1) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__controls:nth-of-type(1) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(1) {
  background-color: $dune-color-white;
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__track {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__slide:nth-of-type(2) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__controls:nth-of-type(2) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(2) {
  background-color: $dune-color-white;
}
.carousel__activator:nth-of-type(3):checked ~ .carousel__track {
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
}
.carousel__activator:nth-of-type(3):checked ~ .carousel__slide:nth-of-type(3) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(3):checked ~ .carousel__controls:nth-of-type(3) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(3):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(3) {
  background-color: $dune-color-white;
}
.carousel__activator:nth-of-type(4):checked ~ .carousel__track {
  -webkit-transform: translateX(-300%);
  transform: translateX(-300%);
}
.carousel__activator:nth-of-type(4):checked ~ .carousel__slide:nth-of-type(4) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(4):checked ~ .carousel__controls:nth-of-type(4) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(4):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(4) {
  background-color: $dune-color-white;
}
.carousel__activator:nth-of-type(5):checked ~ .carousel__track {
  -webkit-transform: translateX(-400%);
  transform: translateX(-400%);
}
.carousel__activator:nth-of-type(5):checked ~ .carousel__slide:nth-of-type(5) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(5):checked ~ .carousel__controls:nth-of-type(5) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(5):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(5) {
  background-color: $dune-color-white;
}
.carousel__activator:nth-of-type(6):checked ~ .carousel__track {
  -webkit-transform: translateX(-500%);
  transform: translateX(-500%);
}
.carousel__activator:nth-of-type(6):checked ~ .carousel__slide:nth-of-type(6) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(6):checked ~ .carousel__controls:nth-of-type(6) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(6):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(6) {
  background-color: $dune-color-white;
}
.carousel__activator:nth-of-type(7):checked ~ .carousel__track {
  -webkit-transform: translateX(-600%);
  transform: translateX(-600%);
}
.carousel__activator:nth-of-type(7):checked ~ .carousel__slide:nth-of-type(7) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(7):checked ~ .carousel__controls:nth-of-type(7) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(7):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(7) {
  background-color: $dune-color-white;
}
.carousel__activator:nth-of-type(8):checked ~ .carousel__track {
  -webkit-transform: translateX(-700%);
  transform: translateX(-700%);
}
.carousel__activator:nth-of-type(8):checked ~ .carousel__slide:nth-of-type(8) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.carousel__activator:nth-of-type(8):checked ~ .carousel__controls:nth-of-type(8) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(8):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(8) {
  background-color: $dune-color-white;
}



.carousel__control {
  height: 15px;
  width: 15px;
  margin-top: -15px;
  top: 50%;
  position: absolute;
  display: block;
  cursor: pointer;
  border-width: 2px 2px 0 0;
  border-style: solid;
  border-color: $dune-color-white;
  opacity: 0.35;
  outline: 0;
  z-index: 3;
}
.carousel__control:hover {
  opacity: 1;
}
.carousel__control--backward {
  left: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.carousel__control--forward {
  right: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.carousel__track {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}
.carousel__track .carousel__slide {
  display: block;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.carousel__track .carousel__slide:nth-of-type(1) {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.carousel__track .carousel__slide:nth-of-type(2) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.carousel__track .carousel__slide:nth-of-type(3) {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}
.carousel__track .carousel__slide:nth-of-type(4) {
  -webkit-transform: translateX(300%);
  transform: translateX(300%);
}
.carousel__track .carousel__slide:nth-of-type(5) {
  -webkit-transform: translateX(400%);
  transform: translateX(400%);
}
.carousel__track .carousel__slide:nth-of-type(6) {
  -webkit-transform: translateX(500%);
  transform: translateX(500%);
}
.carousel__track .carousel__slide:nth-of-type(7) {
  -webkit-transform: translateX(600%);
  transform: translateX(600%);
}
.carousel__track .carousel__slide:nth-of-type(8) {
  -webkit-transform: translateX(700%);
  transform: translateX(700%);
}
.carousel--scale .carousel__slide {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.carousel__slide {
  height: 100%;
  position: absolute;
  overflow-y: auto;
  opacity: 0;
}
/**
  * Theming
*/
.carousel-container {
  display: inline-block;
  width: 100%;
}
.carousel__slide {
  overflow: hidden;
}

.carousel__slide,
.carousel--thumb .carousel__indicators .carousel__indicator {
  background-size: cover;
  background-position: center;
}

