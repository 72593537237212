.dune-social-network-icon {
  position: relative;
  text-align: center;
  width: 0px;
  height: 0px;
  padding: 19px;
  border-top-right-radius: 19px;
  border-top-left-radius: 19px;
  border-bottom-right-radius: 19px;
  border-bottom-left-radius: 19px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  -khtml-border-radius: 20px 20px 20px 20px;
  float: left;
  margin: 0 5px 0 0;
  cursor: pointer;
  background: $dune-color-corail;
  color: $dune-color-white;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;

  svg {
    font-size: 19px;
    position: absolute;
  }
}

.dune-social-network-icon.facebook {
  svg {
    left: 13px;
    top: 10px;
  }
}

.dune-social-network-icon.youtube {
  svg {
    left: 8.5px;
    top: 11px;
  }
}

.dune-social-network-icon.instagram {
  svg {
    left: 11px;
    top: 10px;
  }
}