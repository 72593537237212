aside {
  border: 2px solid $dune-color-corail;
  @include border-radius(5px);
  padding: 1rem;
  margin: 30px 0;

  ul {
    list-style: none;
    padding-left: 0;

    li {
      &:not(.list-title) {
        margin: 10px 0 30px 34px;
        font-weight: 300;
      }

      &.list-title-margin {
        margin-bottom: 0;
      }

      &.li-responsive {
        margin-left: 0;
      }

      &.list-title, &.list-title-margin {
        img, svg {
          height: 28px;
          width: 30px;
          vertical-align: top;
          margin-right: 5px;
        }

        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }

  &:not(.aside-lg) {
    @include media-breakpoint-up(md) {
      border-radius: 0;
      border-top: none;
      border-bottom: none;
      border-right: none;
      height: 100%;
      margin: 0;
      ul {
        li {
          &.li-responsive {
            margin-left: 34px;
          }
        }
      }
    }
  }
  &.aside-lg {
    @include media-breakpoint-up(lg) {
      border-radius: 0;
      border-top: none;
      border-bottom: none;
      border-right: none;
      height: 100%;
      margin: 0;
      ul {
        li {
          &.li-responsive {
            margin-left: 34px;
          }
        }
      }
    }
  }
}