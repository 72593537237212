.tips {
    padding: 0px 0px 10px;
    position: relative;
    &:before {
        content: '';
        width: 150px;
        height: 129px;
        background: url(../../../images/new/testimonials-quote.png) no-repeat;
        background-size: contain;
        display: block;
        margin: 15px auto;
        position: absolute;
        left: 135px;
        top: 50px;
    }
    &::after {
        content: "";
        position: absolute;
        background-size: contain;
        background-repeat: repeat-x;
        width: 100%;
        background-image: url(../../../images/new/testimonials-bottom-green-separator.png);
        bottom: -12px;
        height: 66px;
    }  
    &Title {
        text-transform: none;
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        color: black;
        text-align: center;
        &:after {
            content: '';
            width: 87px;
            height: 12px;
            background: url(../../../images/new/title-deco.png) no-repeat;
            background-size: contain;
            display: block;
            margin: 15px auto 30px;
        }
    }
    &Intro {
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        max-width: 1096px;
        padding: 0 20px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 24px;
    }
    &Slider {
        position: relative;
        padding: 0 180px 80px;
        &.slick-dotted.slick-slider {
            margin-bottom: 0;
        }
        &::before {
            content: "";
            position: absolute;
            background-size: contain;
            background-repeat: repeat-x;
            width: 100%;
            background-image: url(../../../images/new/testimonials-top-green-separator.png);
            bottom: calc(80% - 33px);
            left: 0;
            height: 66px;
        }
        &::after {
            content: "";
            position: absolute;
            background-size: cover;
            width: 100%;
            background: $csecondaryLight;
            left: 0;
            bottom: 0;
            height: 80%;
        }
        &Item {
            display: flex;
            column-gap: 30px;
            &Monitor {
                flex: 1;
                border-radius: 10px;
                overflow: hidden;
                &Picture {
                    height: 356px;
                    width: 100%;
                    border-radius: 10px;
                    overflow: hidden;
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    position: relative;
                    z-index: 2;
                }
                &Infos {
                    background: white;
                    padding: 32px 16px 16px;
                    position: relative;
                    z-index: 1;
                    margin-top: -10px;
                    height: calc(100% - 346px);
                    &Name {
                        color: $csecondary;
                        text-transform: none;
                        text-align: center;
                        font-weight: 700;
                        font-size: 24px;
                    }
                    &Details {
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }
            .destinationsFavoriteSliderCard {
                flex: 2;
            }
        }
        .slick {
            &-slide {
                margin: 0 12px;
                position: relative;
                z-index: 2;
            }
            &-list {
                margin: 0 -12px;
                position: relative;
                z-index: 1;
            }
            &-track {
                display: flex !important;
            }
            &-dots {
                bottom: 30px;
                left: 0;
                z-index: 1;
                li {
                    width: 6px;
                    height: 6px;
                    margin: 0 4px;
                    button {
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                        background: $csecondary;
                        opacity: 0.3;
                        &:before {
                            display: none;
                        }
                    }
                    &.slick-active {
                        width: 21px;
                        button {
                            opacity: 1;
                        }
                    }
                }
            }
            &-arrow {
                width: 54px;
                height: 94px;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                &::before {
                    content: '';
                    width: 18px;
                    height: 28px;
                    background-size: contain;
                }
                &:hover,
                &:focus {
                    background: white;
                }
                &.slick-prev {
                    left: 126px;
                    border-radius: 100px 0 0 100px;
                    &::before {
                        background-image: url(../../../images/new/prev.png);
                    }
                }
                &.slick-next {
                    right: 126px;
                    border-radius: 0 100px 100px 0;
                    &::before {
                        background-image: url(../../../images/new/next.png);
                        width: 17px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .tipsSlider {
        padding: 0 50px 80px;
        &Item .destinationsFavoriteSliderCard {
            flex: 1.5;
        }
        .slick-arrow {
            width: 50px;
            &.slick-prev {
                left: 0;
            }
            &.slick-next {
                right: 0;
            }
        }
    }
}
@media (max-width: 991px) {
    .tips {
        &::before {
            left: 50px;
        }
        &Slider {
            visibility: hidden;
            &.slick-initialized {
                visibility: visible;
            }
            &Item {
                column-gap: 20px;
                .destinationsFavoriteSliderCard {
                    flex: 1.2;
                    &ContentName {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
                &Monitor {
                    &Picture {
                        height: 266px;
                    }
                    &Infos {
                        height: calc(100% - 246px);
                        &Name {
                            font-size: 18px;
                        }
                        &Details {
                            font-size: 14px;
                        }
                    }
                } 
            }
        }
    } 
}

@media (max-width: 767px) {
    .tipsSlider {
        &ItemMonitor {
            display: none;
        }
    }
}


@media (max-width: 575px) {
    .tipsSlider {
        padding: 0 20px 30px;
    }
}