/*------------------------------------
  Carousel
------------------------------------*/
.u-carousel {
  display: block;
  width: 100%;

  > li {
    display: table-cell;
    width: 1%;
    text-align: center;
    float: none;
    &:nth-child(1) .u-dot-line-v1__inner::before {
      content: "1";
      padding-top: 10px;
    }

    &:nth-child(2) .u-dot-line-v1__inner::before {
      content: "2";
      padding-top: 10px;
    }

    &:nth-child(3) .u-dot-line-v1__inner::before {
      content: "3";
      padding-top: 10px;
    }
    a {
      color: $dune-color-grey;
      text-transform: uppercase;
    }
  }

  span {
    display: inline-block;
  }
}

@media (min-width: 576px) {
  .u-carousel {
    display: block;
    width: 100%;

    > li {
      display: table-cell;
      width: 1%;
      text-align: center;
      float: none;
    }

    span {
      display: inline-block;
    }
  }
}


/*------------------------------------
  Dot line v1
------------------------------------*/
.u-dot-line-v1 {
  position: relative;
  display: block;
  width: 100%;
  font-size: 0;

  &::after, &::before {
    color: $dune-color-grey;
    content: "";
    position: absolute;
    top: 50%;
    display: block;
    width: calc(50% + 30px);
    border-top-width: 1px;
    border-top-style: solid;
    margin-top: -1px;
  }

  &::before {
    left: -30px;
  }

  &::after {
    right: -30px;
  }

}

li:first-child .u-dot-line-v1::before,
li:last-child .u-dot-line-v1::after {
  display: none;
}

.u-dot-line-v1::after {
  right: -30px;

}

.u-dot-line-v1__inner {
  background-color: $dune-color-grey-light;
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 70px;
  height: 70px;
  border-width: 1px;
  border-style: solid;
  @include border-radius(50%);
  border-color: $dune-color-grey-light;

  &::before {
    color: $dune-color-grey;
    font-size: 30px;
    top: 16px;
    position: absolute;
    left: 50%;
    width: 12px;
    height: 12px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @include border-radius(50%);
    font-style: normal;
  }
}

/*------------------------------------
  Slick
------------------------------------*/
.slick-active .u-dot-line-v1__inner::before, .slick-active {

}

.slick-active {
  a {
    color: $dune-color-corail!important;
  }
  .u-dot-line-v1__inner {
    background-color: $dune-color-corail;

    &::before {
      color: $dune-color-white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .u-carousel {
    padding: 0 10%;
  }
}