.meeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $csecondaryLight;
    position: relative;
    margin-top: 80px;
    padding-bottom: 100px;
    &::before {
        content: "";
        position: absolute;
        background-size: contain;
        background-repeat: repeat-x;
        width: 100%;
        z-index: -1;
        background-image: url(../../../images/new/testimonials-top-green-separator.png);
        top: -36px;
        height: 66px;
    }
    &::after {
        content: "";
        background: url(../../../images/new/meeting-wave.png) no-repeat;
        background-size: contain;
        width: 518px;
        height: 281.5px;
        left: 0;
        bottom: 60px;
        display: block;
        position: absolute;
    }
    &Title {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 26px;
        color: black;
        text-transform: none;
        font-weight: 700;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 0 20px;
    }
    &List {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 800px;
        padding: 0;
        margin: 0 auto;
        gap: 10px;
        position: relative;
        z-index: 1;
        &Item {
            width: 160px;
            min-width: 160px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &Name {
                color: $cprimary;
                text-align: center;
            }
            &Picto {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}
@media (max-width: 1199px) {
    .meeting {
        &::before {
            background-size: cover;
        }
    }
    .meetingListItem {
        width: 140px;
        min-width: 140px;
        &Name {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

@media (max-width: 991px) {
    .meetingListItem {
        width: 110px;
        min-width: 110px;
    }
}

@media (max-width: 767px) {
    .meetingListItem {
        width: 120px;
        min-width: 80px;
    }
}

@media (max-width: 575px) {
    .meeting {
        &Title {
            font-size: 28px;
        }
        &::after {
            width: 100%;
        }
    }
}