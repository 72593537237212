#slide {
  &.slider-horizontal {
    margin-top: 5px;
  }
  .slider-selection, .slider-track-high {
    background: $dune-color-corail;
  }
  .slider-handle {
    background-color: $dune-color-white;
    background-image: none;
    box-shadow:5px 5px 10px grey;
    border: 1px solid $dune-color-grey-light;
    height: 37px;
    width: 37px;
    top: -8px;
  }
  .tooltip.tooltip-main.top {
    opacity: 1;
    margin-top: -40px;
    z-index: 1;
    .tooltip-inner {
      margin-left: 15px;
      background-color: transparent;
      color: coral;
      font-family: Dosis;
      @include fontSize($font-size-base)
    }
  }
}
