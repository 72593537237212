// ------------------------------
// - Spacer module
// ------------------------------

// No margin
// ------------------------------
@each $side in $sides {
  .m#{$side}--0 {
    margin: 0 !important;
  }
}

// Margin
// ------------------------------
@each $key, $spacer in $spacers {
  .m-#{$key} {
    margin: $spacer !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $key, $spacer in $spacers {
      .m-#{$breakpoint}-#{$key} {
        margin: $spacer !important;
      }
    }
  }
}

// Margin Top
// ------------------------------
@each $key, $spacer in $spacers {
  .mt-#{$key} {
    margin-top: $spacer !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $key, $spacer in $spacers {
      .mt-#{$breakpoint}-#{$key} {
        margin-top: $spacer !important;
      }
    }
  }
}


// Margin Bottom
// ------------------------------
@each $key, $spacer in $spacers {
  .mb-#{$key} {
    margin-bottom: $spacer !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $key, $spacer in $spacers {
      .mb-#{$breakpoint}-#{$key} {
        margin-bottom: $spacer !important;
      }
    }
  }
}

.mb-big {
  margin-bottom: 80px;
}
// Margin Left
// ------------------------------
@each $key, $spacer in $spacers {
  .ml-#{$key} {
    margin-left: $spacer !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $key, $spacer in $spacers {
      .ml-#{$breakpoint}-#{$key} {
        margin-left: $spacer !important;
      }
    }
  }
}

// Margin Right
// ------------------------------
@each $key, $spacer in $spacers {
  .mr-#{$key} {
    margin-right: $spacer !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $key, $spacer in $spacers {
      .mr-#{$breakpoint}-#{$key} {
        margin-right: $spacer !important;
      }
    }
  }
}

// Spacer
// ------------------------------

@include media-breakpoint-only(md) {
  .spacer-md {
    height: 40px;
    width: 100%;
  }
}

// Width
// ------------------------------
.w-33 {
  width: 33% !important;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .w-#{$breakpoint}-25 {
      width: 25% !important;
    }
    .w-#{$breakpoint}-33 {
      width: 33% !important;
    }
    .w-#{$breakpoint}-50 {
      width: 50% !important;
    }
    .w-#{$breakpoint}-75 {
      width: 75% !important;
    }
    .w-#{$breakpoint}-100 {
      width: 100% !important;
    }
    .w-#{$breakpoint}-auto {
      width: auto !important;
    }
  }
}

// Padding
// ------------------------------

.p-33 {
  width: 33% !important;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .p-#{$breakpoint}-25 {
      width: 25% !important;
    }
    .p-#{$breakpoint}-33 {
      width: 33% !important;
    }
    .p-#{$breakpoint}-50 {
      width: 50% !important;
    }
    .p-#{$breakpoint}-75 {
      width: 75% !important;
    }
    .p-#{$breakpoint}-100 {
      width: 100% !important;
    }
    .p-#{$breakpoint}-auto {
      width: auto !important;
    }
    .p-#{$breakpoint}-inherit {
      width: inherit !important;
    }
  }
}