#quote {
  form {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;

    .form-label {
      text-transform: uppercase;
    }

    .search-activities {
      & + .select2-container {
        &::after {
          content: "\f002";
          font-family: "Font Awesome";
          color: #ED7208;
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }
    }

    .checkmark {
      img, svg {
        margin-bottom: 8px;
        height: 36px!important;
      }
    }

    &.immersion {
      & > div {
        &:nth-child(1n+2) {
          margin-top: 50px;
        }

        &:last-child {
          margin-top: 40px;
          justify-content: center;

        }
      }

      @include media-breakpoint-up(md) {
        & > div {
          &:nth-child(1n+2) {
            margin-top: 94px;
          }

          &:last-child {
            margin-top: 40px;
          }
        }
      }
    }

    > div:not(.collection), div.collection > div {
      padding-left: 15px;
      padding-right: 15px;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      margin-top: 20px;

      label.form-label {
        text-align: left;
        margin-left: 15px;

        &.label-column {
          flex: 1 100%;
        }

        &.label-inline {
          flex: 1 1 0%;
        }
      }

      > .recap {
        border: 1px solid $dune-color-grey;
        @include border-radius(5px);
        background-color: $dune-color-white;
        padding: 24px 10px;
        width: 100%;
        text-align: left;
        height: 250px;
        overflow: scroll;

        ul {
          list-style: none;
          padding-left: 0;

          li {
            padding-left: 0;
            text-transform: uppercase;
            margin-bottom: 8px;

            i, svg {
              color: $dune-color-grey;
              margin-right: 8px;
            }
          }
        }

        &.inline {
          height: auto;

          ul {
            li {
              float: left;

              &::after {
                padding-right: 0.75rem;
                padding-left: 0.75rem;
                color: $dune-color-dark-blue;
                content: "|";
              }

              &:last-child {
                &::after {
                  content: "";
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    form {
      padding-left: 1%;
      padding-right: 1%;
    }
  }
}