body#detailpromo {
  #header {
    .carousel {
      padding-top: 0;
      padding-bottom: 0;

      .carousel-item {
        padding-top: 0;

        img.img-slide {
          visibility: hidden;
          height: 400px;
        }
      }

      .header-caption {
        top: 0;
      }

      .dune-carousel-control-prev {
        right: auto;
        left: 30px;
      }

      .dune-carousel-control-next {
        right: 30px;
      }

      .dune-carousel-control-prev, .dune-carousel-control-next {
        display: block !important;
        position: absolute;
        top: 36%;
        color: $dune-color-white;
        line-height: 37px;
        border: 1px solid $dune-color-white;
        @include border-radius(50%);
        text-align: center;
        height: 40px;
        width: 40px;
        z-index: 15;
      }
    }

    @include media-breakpoint-up(md) {
      .carousel {

        .dune-carousel-control-prev, .dune-carousel-control-next {
          top: 50%;
        }

        .dune-carousel-control-prev {
          right: auto;
          left: 60px;
        }

        .dune-carousel-control-next {
          right: 60px;
        }

        .carousel-item {
          height: 700px;

          img.img-slide {
            height: 700px;
          }
        }

        .carousel-caption {
          top: 100px;

          .carousel-sub-caption {
            width: 390px;
            margin-top: auto;
            position: relative;
          }
        }
      }
    }
  }
}