#pays,
#paysSub {
  .nav__wrapper {
      .navigation-menu {
          color: #000B1D;
          background: white;
      }
  }
  .expedition-nav svg {
      color: var(--primary-red);
  }
}
.pays {
  &Header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40vw;
    width: 100%;
    &Title,
    &Coordinate {
      text-transform: none;
      color: white;
      text-align: center;
      position: relative;
      z-index: 2;
      text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
    }
    &Title {
      font-size: 98px;
      line-height: 98px;
      font-family: 'Abril Fatface';
      margin-bottom: 26px;
    }
    &Coordinate {
      font-size: 32px;
      font-weight: 700;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 113px;
      background: url(../../../images/new/mask.png) no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 1;
    }
    &Banner {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
    }
  }
  &Gallery {
    margin: -10vw auto 0;
    padding: 0 100px;
    position: relative;
    z-index: 1;
    &List {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-auto-rows: 15vw;
      gap: 16px;
      margin-bottom: 16px;
      &Item {
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        &:nth-child(1) {
          grid-column: span 3;
        }
        &:nth-child(2) {
          grid-column: span 2;
        }
        &:nth-child(3) {
          grid-column: span 2;
        }
        &:nth-child(4) {
          grid-column: span 4;
        }
        &:nth-child(5) {
          grid-column: span 3;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  &Details {
    display: flex;
    align-items: center;
    gap: 34px;
    &Picture {
      flex: 1;
      border-radius: 10px;
      overflow: hidden;
      height: 100%;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &Content {
      flex: 2.2;
      &Title {
        text-transform: none;
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        color: black;
        &:after {
            content: '';
            width: 87px;
            height: 12px;
            background: url(../../../images/new/title-deco.png) no-repeat;
            background-size: contain;
            display: block;
            margin: 15px 0;
        }
      }
      &Text {
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &Advantages {
    background: url(../../../images/new/bg-advantages.png) no-repeat;
    background-size: 1060px;
    background-position: center 0;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: -80px;
    &Title {
      text-transform: none;
      color: black;
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 8px;
      font-weight: 700;
    }
    &Intro {
      text-align: center;
      margin-bottom: 24px;
      font-size: 14px;
    }
    &List {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      column-gap: 30px;
      &Item {
        max-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &Picto {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background: $ctertiaryLight;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 40px;
            max-height: 40px;
            height: auto;
          }
        }
        &Text {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
  &Sub {
    &Title {
      font-size: 32px;
      line-height: 32px;
      text-transform: none;
      color: #333333;
      margin-bottom: 8px;
      text-align: center;
      padding: 0 20px;
      font-weight: 700;
    }
    &Intro {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      padding: 0 20px;
      margin-bottom: 40px;
    }
    &Container {
      margin-left: 50px;
      margin-right: 50px;
    }
    &Header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 80px;
      border-radius: 20px;
      overflow: hidden;
      min-height: 530px;
      padding: 0;
      position: relative;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
      &Name {
        font-family: 'Abril Fatface';
        font-size: 98px;
        line-height: 98px;
        text-transform: none;
        color: white;
        margin: 0 0 25px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
      }
      &Title {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: white;
        text-transform: none;
        margin: 0 0 80px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
      }
    }
    &Infos {
      background: white;
      margin: -110px 90px 0;
      border-radius: 20px;
      padding: 20px 60px;
      box-shadow: 0 4px 42px rgba(0,0,0,0.08);
      &List {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        &Item {
          font-size: 16px;
          &Label {
            color: $cgrey3;
            font-weight: 500;
            margin-bottom: 8px;
          }
          &Details {
            display: flex;
            column-gap: 5px;
          }
        }
      }
    }
    &Details {
      display: flex;
      align-items: center;
      gap: 40px;
      padding-bottom: 0;
      &Pictures {
        flex: 1;
        position: relative;
        img {
          border-radius: 20px;
          &:first-child {
            width: 88%;
            position: relative;
            z-index: 1;
            margin-top: 25px;
          }
          &:last-child {
            width: 70%;
            max-height: 80%;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
          }
        }
      }
      &Content {
        flex: 1.8;
        &Title {
          text-transform: none;
          font-size: 32px;
          line-height: 32px;
          font-weight: 700;
          color: black;
          &:after {
              content: '';
              width: 87px;
              height: 12px;
              background: url(../../../images/new/title-deco.png) no-repeat;
              background-size: contain;
              display: block;
              margin: 15px 0;
          }
        }
        &Description {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    &Sites {
      &Content {
        display: flex;
        gap: 60px;
        &Picture {
          flex: 1;
          img {
            border-radius: 20px;
          }
        }
        &List {
          flex: 1.3;
          &Item {
            background: white;
            padding: 30px 65px;
            margin-bottom: 30px;
            &.selected {
              border-radius: 20px;
              box-shadow: 0 4px 42px rgba(0,0,0,0.08);
            }
            &Title {
              text-transform: none;
              font-weight: 700;
              margin-bottom: 15px;
              font-family: 'Abril Fatface';
              font-size: 24px;
              line-height: 24px;
            }
            &Text {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
    &Calendar {
      position: relative;
      background: $csecondaryLight;
      margin-top: 30px;
      padding-bottom: 0;
      &::before {
        content: "";
        position: absolute;
        background-size: cover;
        width: 100%;
        background-image: url(../../../images/new/light-green-separator.png);
        top: -33px;
        height: 66px;
      }
      &Name {
        font-family: 'Abril Fatface';
        font-size: 24px;
        line-height: 24px;
        text-transform: none;
        text-align: center;
        margin-bottom: 20px;
      }
      .paysSubInfos {
        margin: 0 auto 30px;
        max-width: 675px;
      }
      .nav {
        &-tabs {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          column-gap: 10px;
          position: sticky;
          top: 114px;
          z-index: 10;
          background: $csecondaryLight;
        }
        &-link {
          font-size: 18px;
          font-weight: 600;
          color: $ctertiary;
          padding: 15px;
          margin: 0;
          border: 0;
          &.active {
            background: none;
            color: $cprimary;
            position: relative;
            border: 0;
            &:after {
              content: "";
              background: $cprimary;
              width: 100%;
              height: 5px;
              position: absolute;
              bottom: -9px;
              left: 0;
            }
          }
          &:hover {
              border: 0;
              color: $cprimary;
          }
        }
        &-item {
            padding-bottom: 4px;
        }
      }
      .tab {
        &-content {
          background: white;
          border-radius: 50px 50px 0 0;
          position: relative;
          padding: 30px 30px 80px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .paysSub {
    &Container {
      margin-left: 20px;
      margin-right: 20px;
    }
    &Header {
      margin: 0 20px 80px;
      min-height: 440px;
      &Name {
          font-size: 75px;
          line-height: 75px;
      }
      &Title {
          font-size: 28px;
          line-height: 28px;
      }
    }
    &Details {
      gap: 30px;
    }
    &Infos {
      margin: -110px 50px 0;
      padding: 20px;
    }
    &SitesContent {
      gap: 30px;
      &ListItem {
        padding: 30px;
        margin-bottom: 20px;
      }
    }
  }
}


@media (max-width: 991px) {
  .pays {
    &Header {
      height: 55vw;
      max-height: 450px;
    }
    &Details {
      flex-direction: column;
    }
    &Gallery {
      &List {
        grid-auto-rows: 20vw;
      }
    }
    &Sub {
      &Details {
        gap: 20px;
        &ContentDescription {
          font-size: 14px;
          line-height: 18px;
        }
      }
      &SitesContent {
        gap: 20px;
        &List {
          flex: 1;
          &ItemText {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
      &Calendar {
        .nav {
          &-link.active:after {
            bottom: -5px;
          }
          &-tabs {
            top: 74px;
          }
        }
      }
      &Infos {
        margin: -110px 20px 0;
        &List {
          column-gap: 10px;
          &Item {
            &Details,
            &Label {
              font-size: 14px;
            }
          }
        }
      } 
    }
  }
}


@media (max-width: 767px) {
  .pays {
    &Gallery {
      padding: 0 50px;
      &List {
        grid-auto-rows: 25vw;
      }
    }
    &Header {
      &Title {
        font-size: 60px;
        line-height: 60px;
      }
      &Coordinate {
        font-size: 28px;
      }
    }
    &Sub {
      &Header {
        min-height: 80vw;
        margin-bottom: 40px;
        &Name {
            font-size: 55px;
            margin-bottom: 10px;
        }
        &Title {
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 20px;
            text-align: center;
        }
      }
      &Infos {
        margin-top: 0;
        &List {
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
        } 
      }
      &SitesContent {
        flex-direction: column;
      }
      &Details {
        flex-direction: column;
      }
      &Calendar .nav-link {
        font-size: 15px;
        padding: 10px;
      }
    }
  }
}
@media (max-width: 575px) {
  .pays {
    &Header {
      height: 75vw;
      &Title {
        font-size: 40px;
        line-height: 40px;
      }
      &Coordinate {
        font-size: 22px;
      }
    }
    &Gallery {
      padding: 0 20px;
      &List {
        gap: 10px;
      }
    }
    &Advantages {
      &Title {
        font-size: 28px;
        line-height: 28px;
        padding: 0 20px;
      }
      &List {
        column-gap: 20px;
        &Item {
          max-width: 110px;
          &Text {
            font-size: 14px;
          }
        }
      } 
    }
    &Sub {
      &HeaderTitle {
        padding: 0 20px;
      }
      &InfosList {
        flex-direction: column;
        justify-content: flex-start;
      }
      &Calendar .paysSubInfosList {
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}