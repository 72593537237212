html {
  -webkit-font-smoothing: antialiased;
}
body {
  background-color: $dune-color-white;
  font-family: $font-main;
  font-size: $font-size-base;
  line-height: $line-height-base;
  padding-top: 108px;
  font-weight: normal;
  &.noScroll {
    overflow: hidden;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 150px;
  }
}

p {
  font-weight: lighter;
  font-family: OpenSans;
}

a {
  color: $dune-color-dark-blue;
  font-weight: normal;
}

section {
  padding: 60px 0;

  &.no-padding {
    padding: 0 0 !important;
  }

  &.x-padding {
    padding-left: 5%;
    padding-right: 5%;
    @include media-breakpoint-up(xl) {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}

hr {
  width: 100%;
  border: 1px solid $dune-color-corail;
}

.dune-bg-grey-line {
  background-image: url($img-path + "/pattern-white.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dune-bg-coral-line {
  background-image: url($img-path + "/pattern-coral.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dune-bg-blue-line {
  background-image: url($img-path + "/pattern-blue.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $dune-color-dark-blue;
}

.ft-family-open {
  font-family: "OpenSans";
}

.ft-family-Dosis {
  font-family: "Dosis";
}

.line-break {
  width: 100%;
}

.scroll {
  width: 100%;
  overflow: scroll;
}

.scroll-x {
  width: 100%;
  overflow-x: scroll;
}

.grecaptcha-badge {
  visibility: hidden;
}

.top {
  top: 0;
}

.white-mask {
  left: -1000px;
  top: 0;
  right: 0;
}

.h-30px {
  height: 30px !important;
  width: 30px !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @for $size from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$size} {
        max-width: percentage($size / $grid-columns);
      }
    }
  }
}

.img-fluid {
  min-height: 0.01px; /* FIX IE 11 */
}

.column-count-2 {
  column-count: 1;
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
  @include media-breakpoint-up(xl) {
    column-count: 3;
  }
}

.read-more {
  &.collapsing {
    @include transition(none)
  }
}

.ckeditor {
  font-weight: lighter;
  font-family: OpenSans;

  h2 {
    @extend .light;
  }

  h3 {
    @include fontSize(30px);
  }

  h4 {
    font-weight: lighter;
  }

  input[type=button] {
    @extend .btn-dune-md;

    &:focus {
      box-shadow: none;
      outline-color: transparent;
    }
  }

  strong {
    font-weight: bold;
  }

  ul {
    font-weight: lighter;

    li {
      font-weight: lighter;
    }
  }

}

.ekko-lightbox {
  .modal-dialog {
    margin-top: 200px;
  }
}

.item-color-corail {
  color: $dune-color-corail !important;
}

.cursor-hand:hover {
  cursor: pointer;
}

.tooltip-inner {
  max-width: 100%;
}

.ekko-lightbox .modal-dialog {
  flex: 100% !important;
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 767px) {
  body  {
      font-size: 14px;
  }
}