#products {
  #main {
    article {
      .cell {
        flex: 1 1 0%;
      }

      p {
        font-weight: 300;
      }
    }
  }

  #content {
    .img-map {
      border: 2px solid $dune-color-corail;
    }

    #program {
      @include media-breakpoint-up(md) {
        max-width: 724px;
        margin: auto;
      }
    }

    #boat, #accommodations {
      .carousel {
        font-family: "OpenSans";

        .carousel-inner {
          .carousel-item {
            padding: 0;

            .card {
              border: none;
              background-color: transparent;

              img {
                @include border-radius(10px);
                max-height: 372px;
              }

              .tag-center-top {
                text-transform: uppercase;
                position: absolute;
                border: 2px solid $dune-color-dark-blue;
                @include border-radius(5px);
                top: 25px;
                left: 50%;
                transform: translate(-50%, 0);
                color: $dune-color-dark-blue;
                padding: 10px 29px;

                @include fontSize(xlg);
              }

              .tag-center-bottom {
                text-transform: uppercase;
                position: absolute;
                bottom: 25px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 100%;
              }

              .card-content {
                flex-grow: 2;
                @include fontSize($font-size-sm);

                .title-boat, .title-accommodation {
                  font-family: "Dosis";
                  padding: 10px 29px;
                  border: 2px solid $dune-color-dark-blue;
                  @include border-radius(10px);
                }

                ul {
                  display: flex;
                  justify-content: space-between;
                  margin-left: 0;
                  margin-top: 33px;
                  padding-left: 0;

                  & > li {
                    float: left;
                    list-style: none;
                    color: $dune-color-corail;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        .carousel {
          .carousel-inner {
            .carousel-indicators {
              bottom: 15px;
            }
          }
        }
      }
    }
  }

  #additional {
    #carouselCruse {
      .card {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  #benefit {
    .container {
      max-width: 724px;
    }

    ul {
      display: inline-block;
      font-family: OpenSans;
      padding-left: 20px;

      li {
        font-weight: 300;
        //@include fontSize($font-size-xs);
      }

      ul {
        padding-top: 5px;
        padding-bottom: 10px;
        list-style: none;

        li:before {
          content: '- ';
        }
      }

      &.features {
        padding-left: 0;
        margin-left: 0;
        font-family: Dosis;
        list-style: none;

        li {
          font-weight: 300;
          margin: 9px 0;
          @include fontSize($font-size-base);

          .title {
            font-weight: normal;
            text-transform: uppercase;
            color: $dune-color-corail;
          }
        }
      }
    }
  }

  #testimony {
    .left {
      @include media-breakpoint-up(md) {
        flex: 0 1 35%;
      }
    }

    .right {
      > div > span {
        position: relative;

        img, svg {
          top: 50%;
          height: 60px;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  #ideas {
    .right > div > span {
      position: relative;

      p {
        position: absolute;
        color: white;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        font-weight: bold;
        bottom: 20px;
      }
    }
  }
}

.product {
  &Container {
    padding: 55px;
    background: white;
    &Title {
      text-transform: none;
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      color: #333333;
      &.center:after {
        margin: 15px auto;
      }
      &.noLine:after {
        display: none;
      }
      &:after {
          content: '';
          width: 87px;
          height: 12px;
          background: url(../../../images/new/title-deco.png) no-repeat;
          background-size: contain;
          display: block;
          margin: 15px 0;
      }
    }
  }
  &Planning {
    &Top {
      position: relative;
      background: $csecondaryLight;
      padding-bottom: 0;
      padding-top: 60px;
      &::before {
          content: "";
          position: absolute;
          background-size: contain;
          background-repeat: repeat-x;
          width: 100%;
          background-image: url(../../../images/new/light-green-separator.png);
          top: -33px;
          height: 66px;
      }
    }
    &Tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      column-gap: 40px;
      position: sticky;
      top: 166px;
      background: $csecondaryLight;
      z-index: 10;
      .nav {
        &-link {
          font-size: 18px;
          font-weight: 600;
          color: $ctertiary;
          padding: 15px;
          margin: 0;
          border: 0;
          &.active {
              background: none;
              color: $csecondary;
              position: relative;
              border: 0;
              &:after {
                  content: "";
                  background: $csecondary;
                  width: 100%;
                  height: 5px;
                  position: absolute;
                  bottom: -9px;
                  left: 0;
              }
          }
          &:hover {
              border: 0;
              color: $csecondary;
          }
        }
        &-item {
            padding-bottom: 4px;
        }
      }
      &Content {
        background: $csecondaryLight;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          background-size: contain;
          background-repeat: repeat-x;
          width: 100%;
          background-image: url(../../../images/new/planning-separator.png);
          bottom: -33px;
          height: 66px;
          z-index: 1;
        }
        .tab-pane {
          border-radius: 50px 50px 0 0;
          overflow: hidden;
          background: $ctertiary;
        }
      }
    }
    &Title {
        color: black;
        text-transform: none;
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 8px;
    }
    &Intro {
        color: black;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 0px;
    }
    &Content {
      display: flex;
      gap: 30px;
      background: $csecondaryLight;
      &Infos {
        color: white;
        flex: 1.1;
        padding: 50px 40px 0px 70px;
        position: relative;
        background: $ctertiary;
        &:after {
          content: "";
          position: absolute;
          right: -80px;
          bottom: 0;
          width: 80px;
          height: 100%;
          background: url(../../../images/new/mask-vertical.png) no-repeat;
          background-size: cover;
          background-position: center right;
          z-index: 1;
        }
        &Title {
            font-family: "Abril Fatface";
            text-transform: none;
            font-size: 32px;
            line-height: 32px;
            margin-bottom: 15px;
            color: white;
        }
        &Day {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          gap: 8px;
          &::before {
            content: '';
            background: url(../../../images/new/pointer.png);
            width: 33px;
            height: 33px;
            background-size: cover;
            display: block;
          }
        }
        &List {
          padding: 0;
          margin: 0 0 32px 0;
          display: flex;
          flex-direction: column;
          gap: 8px;
          &Item {
            display: flex;
            gap: 16px;
            strong {
              min-width: 75px;
            }
          }
        }
        &Text {
            margin-bottom: 38px;
            font-size: 16px;
            line-height: 24px;
        }
        &Picture {
          overflow: hidden;
          img {
              max-width: 100%;
              width: auto;
              height: auto;
              border-radius: 10px;
          }
        }
      }
      &Picture {
        overflow: hidden;
        flex: 1;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .productPlanningTabsContent::after,
  .productPlanningTop::before {
    background-size: cover;
  }
}

@media (max-width: 991px) {
  .productPlanning {
    &Tabs {
      top: 124px;
      gap: 20px;
      .nav {
        &-link {
          padding: 15px 10px;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .productPlanning  {
    &Tabs {
      gap: 2px;
      top: 114px;
      .nav {
        &-link {
            padding: 10px;
            font-size: 14px;
        }
      }
    }
    &Content {
      gap: 20px;
      &Infos {
        flex: 1;
        padding: 40px 20px 0 40px;
        &Title {
          font-size: 28px;
        }
        &Text {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .product {
    &Container {
      padding: 40px 20px;
    }
    &Planning  {
      &Content {
        flex-direction: column;
        background: $ctertiary;
        &Infos {
          padding: 20px 20px 0 20px;
        }
      }
      &Tabs {
        position: relative;
        top: inherit;
        .nav {
          &-link {
              padding: 10px 5px;
              font-size: 13px;
              &.active:after {
                bottom: 0;
              }
          }
          &-item {
            padding-bottom: 0;
          }
        }
        &Content {
          .tab-pane {
            border-radius: 20px 20px 0 0;
          }
        }
      }
    }
  }
}