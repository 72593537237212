#modalSearch, #navbarHeader {
  .modal-header {
    width: 100%;

    svg, img {
      margin: auto;
      height: 150px;
    }

    margin-bottom: 20px;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }


}


#modalSearch {
  input[type="search"] {
    color: $dune-color-white;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $dune-color-white;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $dune-color-white;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $dune-color-white;
    }

    width: 100%;
    height: 30px;
    border: 0;
    border-bottom: 2px solid $dune-color-white;
    border-radius: 0;
    padding: 0;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }
  .typeahead.dropdown-menu {
    padding: 0.5rem;
    background: transparent;
    border: 0;
    color: $dune-color-white;

    > li {
      @include fontSize($font-size-base);
      padding: 4px;

      a {
        color: $dune-color-white !important;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

#navbarHeader {
  input[type="search"] {
    color: $dune-color-black;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $dune-color-black;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $dune-color-black;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $dune-color-black;
    }

    width: 100%;
    height: 30px;
    border: 0;
    border-bottom: 2px solid $dune-color-black;
    border-radius: 0;
    padding: 0;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }
  .typeahead.dropdown-menu {
    padding: 0.5rem;
    background: transparent;
    border: 0;
    color: $dune-color-black;

    > li {
      @include fontSize($font-size-base);
      padding: 4px;

      a {
        color: $dune-color-black !important;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
