// ------------------------------
// - Fonts Family
// ------------------------------

@font-face {
    font-family: 'OpenSans';
    src: url($font-path + '/Open_Sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url($font-path + '/Open_Sans/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url($font-path + '/Dosis/Dosis-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url($font-path + '/Dosis/Dosis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url($font-path + '/Dosis/Dosis-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url($font-path + '/Dosis/Dosis-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url($font-path + '/Dosis/Dosis-ExtraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome';
    src: url("~@fortawesome/fontawesome-free/webfonts/fa-brands-400.ttf") format('truetype');
}

@font-face {
    font-family: 'Font Awesome';
    src: url("~@fortawesome/fontawesome-free/webfonts/fa-solid-900.ttf") format('truetype');
}

// ------------------------------
// - Fonts Size
// ------------------------------

@each $key, $size in $font-sizes {
    .ft-#{$key} {
        @include fontSize($size);
    }
}

.uppercase {
    text-transform: uppercase;
}