ul.timeline {
  text-align: left;
  color: $dune-color-grey;
  font-family: "OpenSans";
  font-size: $font-size-xxs;
  list-style-type: none;
  position: relative;

  &:before {
    content: ' ';
    background: $dune-color-grey;
    display: inline-block;
    position: absolute;
    left: 9px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }

  & > li {
    margin: 20px 0;

    &:before {
      content: ' ';
      background: $dune-color-white;
      display: inline-block;
      position: absolute;
      @include border-radius(50%);
      border: 2px solid $dune-color-grey;
      left: 0;
      width: 20px;
      height: 20px;
      z-index: 400;
    }

    .card-body {
      //flex: 1 1 auto;
      padding: 1.25rem 0;
    }

    .card {
      color: $dune-color-dark-blue;
      border: none;

      img {
        float: left;
        @include border-radius(0.4rem);
        margin: 0 23px 23px 0;
        max-width: 220px;
      }

      ul {
        padding-left: 18px;
        list-style-type: disc;

        li {
          color: $dune-color-dark-blue;
        }
      }
    }

    a {
      color: $dune-color-grey;
      font-weight: bold;

      &[aria-expanded="false"]:after {
        font-family: "Font Awesome";
        content: "\f078";
        margin-left: 10px;
        margin-right: 10px;
      }

      &[aria-expanded="true"] {
        color: $dune-color-dark-blue;
      }

      &[aria-expanded="true"]:after {
        font-family: "Font Awesome";
        content: "\f054";
        margin-left: 10px;
        margin-right: 10px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}