
// Colors
// -------------------------
$body-text-color:               #555;

$dune-color-white: 			    #ffffff;
$dune-color-black:              rgb(0, 0, 0);
$dune-color-red: 			   #ED7208;
$dune-color-corail: #ED7208;
$dune-color-blue: 				rgb(5, 3, 48);
$dune-color-dark-blue: rgb(5, 3, 48);
$dune-color-grey-light: 		rgb(245, 245, 245);
$dune-color-grey: 				rgb(189, 189, 189);
$dune-color-dark-grey: 			rgb(135, 135, 145);
$dune-color-yellow: 			rgb(252, 183, 49);
$main-color:                    $dune-color-dark-blue;

$border-color-2: 			    #ededed;
$border-color-3: 			    #d2d8dd;
$border-color-4: 			    #ccc;
$border-color-5: 			    #ddd;
$cprimary: #ED7208;
$cprimaryLight: #F9E1E6;
$csecondary: #58B99D;
$csecondaryLight: #E5F1EE;
$cgrey: #727272;
$cgrey2: #848485;
$cgrey3: #A0A0A0;
$cgreyLight: #F5F5F5;
$ctertiary: #112E4A;
$ctertiaryLight: #E2EDF8;
//-------------------- Line Height Variables --------------------//
$line-height-base:              1.4;
$line-height-large:           	1.8;
$line-height-none:           	1;

// Typography
// -------------------------
$font-size-base:                16px;
$line-height-base:              20px;
// Font Size
$font-size-xxxs:             $font-size-base - 8px;
$font-size-xxs:              $font-size-base - 6px;
$font-size-xs:               $font-size-base - 4px;
$font-size-sm:               $font-size-base - 2px;
$font-size-md:               $font-size-base + 2px;
$font-size-lg:               $font-size-base + 4px;
$font-size-xlg:              $font-size-base + 6px;


$normal-font-size:              $font-size-base;
$normal-line-height:            $line-height-base;
$font-main: 		        'Raleway', Arial, sans-serif;

// -------------------------
$link-color-active: $main-color;

//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px;	// Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px;	// Extra small devices (portrait phones, less than 576px)

$colours: (
        "white": $dune-color-white,
        "black": $dune-color-black,
        "red": $dune-color-red,
        "corail": $dune-color-corail,
        "blue": $dune-color-blue,
        "dark-blue": $dune-color-dark-blue,
        "grey-light": $dune-color-grey-light,
        "grey": $dune-color-grey,
        "dark-grey": $dune-color-dark-grey,
        "yellow": $dune-color-yellow,
);

// Spacer
$spacer: 1rem;
$spacer-xs-value: 5px;
$spacer-sm-value: 10px;
$spacer-md-value: 15px;
$spacer-lg-value: 20px;

// Spacers, Padding, Color, Text
$classes: ("xxxs", "xxs", "xs", "sm", "md", "lg");
$sides: ("t", "r", "b", "l");
$align: ("left", "right", "center", "justify");
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
);
$dividers: (
        0: 0,
        1: 0.25,
        2: 0.5,
        3: 1,
        4: 1.5,
        5: 3
);
$font-sizes: (
        "xxxs": $font-size-xxxs,
        "xxs": $font-size-xxs,
        "xs": $font-size-xs,
        "sm": $font-size-sm,
        "md": $font-size-md,
        "lg": $font-size-lg,
        "xlg": $font-size-xlg,
);


// Paths
// -------------------------
$img-path: '../../images';
$font-path: '../../../fonts';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
