#leave {
  table.table tr td ul {
    padding: 0;
    li {
      margin: 4px 0;
    }
  }
  table.table tr td ul li img, table.table tr td ul li svg {
    width: 40px;
    max-height: 32px;
    margin-right: 5px;
  }
}