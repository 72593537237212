.dune-rating {
  height: 100%;
  .dune-rating-stars {
    display: inline-block;
    line-height: 16px;
    &.full::before {
      font-family: 'Font Awesome';
      content: "\f005";
      color: $dune-color-yellow;
      font-size: $font-size-xxs;
    }

    &.half::before {
      font-family: 'Font Awesome';
      content: "\f005";
      color: $dune-color-yellow;
      font-size: $font-size-xxs;
    }
  }

  &.sea-stars {
    .dune-rating-stars {
      height: 14px;
      width: 15px;
      &.full {
        &:before {
          content: "";
        }

        background: url("../" + $img-path + "/pictos/rating/starfish-full.png") no-repeat center;
      }

      &.empty {
        &:before {
          content: "";
        }

        background: url("../" + $img-path + "/pictos/rating/starfish.png") no-repeat center;
      }
    }
  }

  &.eyes {
    .dune-rating-stars {
      height: 14px;
      width: 15px;
      &.full {
        &:before {
          font-family: 'Font Awesome';
          content: "\f06e";
          color: $dune-color-corail;
          font-size: $font-size-xxs;
        }
      }

      &.empty {
        &:before {
          font-family: 'Font Awesome';
          content: "\f06e";
          color: $dune-color-grey-light;
          font-size: $font-size-xxs;
        }
      }
    }
  }
  &.stars-corail {
    .dune-rating-stars {
      &.full {
        &:before {
          color: $dune-color-corail;
        }
      }

      &.empty {
        &:before {
          color: $dune-color-white;
        }
      }
    }
  }

  .dune-rating-review {
    display: inline-block;
    @include fontSize(10px);
    margin-left: 7px;
    font-weight: bold;
  }

}