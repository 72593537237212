#travelbook {
  .card-columns {
    column-count: 1;
    @include media-breakpoint-up(md) {
      column-count: 2;
    }
  }
  #main {
    .tab-content {
      max-width: 930px;
      margin: 0 auto;
    }
  }

}