.essentials {
    background: url(../../../images/new/bg-essentials.png) no-repeat;
    background-size: contain;
    background-position: 80px top;
    margin-bottom: -65px;
    &Title {
        text-align: center;
        font-size: 32px;
        line-height: 32px;
        text-transform: none;
        color: #333;
        font-weight: 700;
        margin-bottom: 8px;
    }
    &Intro {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 70px;
    }
    &Slider {
        visibility: hidden;
        &.slick-initialized {
            visibility: visible;
        }
        &Item {
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            height: 100%;
            background-size: cover;
            background-position: center;
            padding: 35px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            &Zone {
                font-size: 14px;
                text-transform: none;
                color: white;
                text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
                font-weight: 700;
            }
            &Country {
                font-size: 3.4vw;
                text-transform: none;
                color: white;
                text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
                font-weight: 700;
            }
            &Question {
                display: none;
                font-size: 3vw;
                line-height: 3vw;
                color: white;
                text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
                font-weight: 700;
            }
        }
        .slick {
            &-slide {
                float: none;
                display: inline-block;
                vertical-align: middle;
                padding: 10px 0px;
                margin: 0 4px;
                background-color: white;
                text-align: center;
                transition: height width 0.5s;

                &.slick-center,
                &.slick-current {
                    z-index: 1;
                    .essentialsSliderItemQuestion {
                        display: block;
                    }
                }
            }
            &-list {
                position: relative;
                z-index: 1;
                overflow: hidden;
            }
            &-track {
                display: flex !important;
                align-items: center;
                transition: none;
            }
            &-arrow {
                width: 40px;
                height: 80px;
                // width: 4vw;
                // height: 6vw;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
                &:hover,
                &:focus {
                    background: white;
                }
                &::before {
                    content: '';
                    width: 18px;
                    height: 28px;
                    background-size: contain;
                }
                &.slick-prev {
                    // border-radius: 100px 0 0 100px;
                    // left: 28.9vw;
                    left: 0;
                    border-radius: 0 100px 100px 0;
                    &::before {
                        background-image: url(../../../images/new/prev.png);
                        margin-right: 10px;
                    }
                }
                &.slick-next {
                    // border-radius: 0 100px 100px 0;
                    // right: 28.9vw;
                    right: 0;
                    border-radius: 100px 0 0 100px;
                    &::before {
                        background-image: url(../../../images/new/next.png);
                        width: 17px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .essentialsSlider {
        height: 35vw;
        .slick {
            &-slide {
                // width: 22vw !important;
                // height: 22vw;
                width: 32vw !important;
                height: 32vw;
                &.slick-center,
                &.slick-current {
                    width: 34vw !important;
                    height: 35vw;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .essentials {
        &Intro {
            margin-bottom: 40px;
        }
        &Slider {
            &Item {
                justify-content: flex-end;
                &Zone {
                    margin-bottom: 0;
                }
                &Country {
                    font-size: 30px;
                    margin-bottom: 0;
                }
            }
            .slick {
                &-list {
                    padding: 0 0 0 20px;
                    margin: 0;
                }
                &-slide {
                    height: 300px;
                    &.slick-center,
                    &.slick-current {
                        height: 300px;
                        .essentialsSliderItemQuestion {
                            display: none;
                        }
                    }
                }
            } 
        }
    }
}
@media (max-width: 767px) {
    .essentials {
        background-size: cover;
        background-position: -80px top;
    }
}
@media (max-width: 575px) {
    .essentials {
        &Title {
            font-size: 28px;
        }
        &Intro {
            padding: 0 20px;
            margin-bottom: 20px;
        }
        &Slider {
            &Item {
                padding: 20px;
            }
        }
    }
}