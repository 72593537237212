/*
nav {
  font-size: $font-size-xs;

  .nav-show {
    margin-top: 10px;
  }
  &.navbar-dune {
    position: absolute;
  }

  .navbar-toggler {
    padding: 0 7px;
    @include border-radius(50%);
    height: 56px;
    width: 56px;
    background-color: $dune-color-corail;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(5,3,48, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  .navbar-brand {
    img, svg {
      height: 60px;
    }
  }

  .navbar-collapse {
    display: flex;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background: $dune-color-dark-blue;
    color: $dune-color-white;
    bottom: 0;
    padding: 30px;
    overflow: scroll;

    .navbar-toggler {
      top: 30px;
      right: 30px;
    }
  }

  .navbar-header {
    top: 0;
    left: 0;
    height: 37px;
    padding: 0 20px;
  }

  .navbar-nav {
    display: block;
    flex: 1 1 0%;
    min-width: 0;

    .nav-item {
      float: left;

      &.show {
        .nav-link {
          color: $dune-color-white;

          &:before {
            font-family: "Font Awesome";
            content: "\f078";
            left: 30px;
            position: absolute;
          }

          .svg-inline--fa {
            display: none;
          }
        }
      }

      .nav-link {
        color: $dune-color-white;
        @include fontSize($font-size-xs);

        &:focus {
          color: $dune-color-white;
        }

        &[aria-expanded=true] {
          text-align: right;
        }

        span {
          &:hover {
            padding-bottom: 15px;
            text-decoration: none;
            border-bottom: 3px solid $dune-color-corail;
            color: $dune-color-white;
          }
        }
      }
    }
  }

  .navbar-nav-right {
    flex: 0 0 auto;
    min-width: 0;

    & li:first-child {
      .nav-link:before {
        content: url("../../../images/pictos/phone.svg");
        fill: $dune-color-dark-blue;
        height: 22px;
        width: 22px;
        display: inline-block;
        transform: translate(0, 6px);
        margin-right: 4px;
      }
    }

    & li:last-child {
      .nav-link:before {
        content: url("../../../images/pictos/people.svg");
        fill: $dune-color-dark-blue;
        height: 22px;
        width: 20px;
        display: inline-block;
        transform: translate(0, 6px);
        margin-right: 4px;
      }
    }
  }

  form {
    color: $dune-color-corail;

    input[type="search"] {
      &::placeholder { !* Chrome, Firefox, Opera, Safari 10.1+ *!
        color: $dune-color-corail;
      }

      &:-ms-input-placeholder { !* Internet Explorer 10-11 *!
        color: $dune-color-corail;
      }

      &::-ms-input-placeholder { !* Microsoft Edge *!
        color: $dune-color-corail;
      }

      width: 150px !important;
      height: 30px;
      border: 0;
      border-bottom: 2px solid $dune-color-corail;
      border-radius: 0;
      padding: 0.375rem 0.75rem 0.375rem 1.5rem;
      background-color: transparent;
    }

    .wrapper {
      &::before {
        font-family: "Font Awesome";
        content: "\f002";
        left: 0;
        bottom: 5px;
        position: absolute;
      }
    }
  }

  .cta-button {
    z-index: 1001;
    right: 56px;
    top: 135px;

    button {
      @include fontSize($font-size-xxs);
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 45px !important;
  }

  @include media-breakpoint-up(lg) {
    padding: 0.5rem 56px !important;
    &.navbar-dune {
      position: fixed;
      height: 151px;
    }
    .navbar-collapse {
      position: static;
      background: $dune-color-white;
      height: 135px;
      color: $dune-color-dark-blue;
      padding: 0;
      overflow: visible;
    }
    .navbar-nav {
      li:first-child {
        margin-bottom: 0;
      }

      .nav-item {
        &.show {
          .nav-link {
            color: $dune-color-dark-blue;

            &:before {
              content: "";
            }

            .svg-inline--fa {
              display: inherit;
            }
          }
        }

        .nav-link {
          color: $dune-color-dark-blue;
          margin-top: auto;

          &:focus {
            color: $dune-color-dark-blue;
          }

          span {
            &:hover {
              color: $dune-color-dark-blue;
            }
          }
        }
      }
    }
    form {
      .wrapper {
        &::before {
          position: relative;
          left: 17px;
          bottom: -4px;
        }
      }
    }


    .navbar-brand {
      img, svg {
        width: 114px;
        height: 100%;
      }
    }
  }
}

!*========================================
Dropdown Menu
========================================*!
nav.navbar-dune {
  .dropdown-menu:not(.mega-dropdown-menu) {
    .dropdown-item {
      @include fontSize($font-size-xs);
    }
    @include media-breakpoint-down(md) {
      background-color: transparent;
      .dropdown-item {
        color: $dune-color-white;

        &:hover {
          color: $dune-color-white;
          background-color: transparent;
        }
      }
    }
  }
}

!*========================================
Mega Menu
========================================*!
nav.navbar-dune {

  .dropdown-menu.mega-dropdown-menu {
    margin: 0;
    border: 0;
    border-radius: 0;
    background-color: $dune-color-dark-blue;

    .close {
      display: none;
      height: 30px;
      width: 30px;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 20;
      opacity: 1;
      text-shadow: none;

      &:hover {
        cursor: pointer;
      }

      img, svg {
        color: $dune-color-corail;
      }
    }

    @include media-breakpoint-up(lg) {
      .close {
        display: block;
      }
      background-image: url("../" + $img-path + "/pictos/diver.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 233px;
      background-position-x: 40%;
      background-color: $dune-color-white;
      border-top: 1px solid $dune-color-grey-light;
    }
  }

  .dropdown-menu.mega-dropdown-menu.show {
    display: flex;
  }

  .mega-dropdown {
    > a::after {
      content: "\f054";
      font-family: "Font Awesome";
      margin-right: auto;
    }

    &.show > a::after {
      content: "";
    }
  }

  @include media-breakpoint-up(lg) {
    .mega-dropdown {
      > a::after {
        content: "\f078";
      }

      &.show > a::after {
        content: "\f054";
      }
    }
  }


  .mega-dropdown-menu {
    @include media-breakpoint-up(lg) {
      padding: 70px 70px;
    }
    height: calc(100vh - 150px);
    overflow: scroll;
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .mega-dropdown-menu-light {
    @extend .mega-dropdown-menu;
    width: auto;
    height: auto;
    position: relative;
    background-image: none;

  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list {
    padding: 0;
    margin: 0;
  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list > li > button {
    font-size: $font-size-xxs;
    padding: 5px 21px;
  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list > .divider-3-left {
    background-image: url("../" + $img-path + "/pictos/vague.svg");
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: 30px 7px;
    height: 7px;
    padding: 0 5px;
    margin: 10px 0;
  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list > li {
    list-style: none;
  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list > li.more {
    font-weight: 700;
  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list > li > a:not([class*='btn']) {
    display: block;
    color: $white;
    padding: 3px 5px;
    @include fontSize($font-size-sm);
    @include media-breakpoint-up(lg) {
      color: $dune-color-dark-blue;
    }
  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list > li.more > a {
    text-decoration: underline;
    color: $dune-color-corail;
    @include font-size($font-size-xxxs);
  }

  .mega-dropdown-menu ul.mega-dropdown-menu-list > li > a:hover,
  .mega-dropdown-menu ul.mega-dropdown-menu-list > li > a:focus {
    text-decoration: none;
  }

  .mega-dropdown-menu .dropdown-header {
    @include fontSize($font-size-xs);
    color: $dune-color-white;
    @include media-breakpoint-up(lg) {
      color: $dune-color-dark-blue;
    }
    text-transform: uppercase;
    padding: 5px 60px 5px 5px;
    line-height: 30px;
  }

}
!*========================================
End Mega Menu
========================================*!
*/
