@import '../core/app';
// ------------------------------
// Global File
// ------------------------------
@import 'global';

// ------------------------------
// Main Files
// ------------------------------
@import 'extra';
@import "partial";

// ------------------------------
// Elements
// ------------------------------
@import 'elements';

// ------------------------------
// Library File
// ------------------------------
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
@import '~bootstrap-slider/dist/css/bootstrap-slider.css';
@import "~ekko-lightbox/dist/ekko-lightbox.css";


// ------------------------------
// Main Pages
// ------------------------------
@import "pages/home";
@import "pages/pays";
@import "pages/products";
@import "pages/region";
@import "pages/boat";
@import "pages/see";
@import "pages/quote";
@import "pages/travelbook";
@import "pages/cruse";
@import "pages/leave";
@import "pages/detailpromo";

