/*========================================
Cards
========================================*/
.card {
  font-family: OpenSans;


  .card-title {
    font-size: $font-size-sm;
    font-weight: bold;
    text-transform: initial;
  }

  .card-text {
    font-size: $font-size-xs;
    font-weight: 300;
  }

  .card-bottom {
    .btn-dune-full-xxxs {
      padding: 12px 15px;
    }
  }

  .card-price {
    p {
      line-height: 30px;
      margin-top: 10px;
      margin-bottom: 0;

      .price {
        font-size: 30px;
        color: $dune-color-corail;
        font-weight: bold;
      }

      del {
        .price-crossed {
          font-weight: 300;
          font-size: $font-size-sm;
        }

        color: $dune-color-corail;
        text-decoration: none;
        position: relative;
        display: inline-block;

        &:before {
          content: " ";
          display: block;
          width: 100%;
          border-top: 2px solid $dune-color-dark-blue;
          height: 12px;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: rotate(-35deg);
        }
      }
    }
  }

  .card-body-quote {
    position: relative;
    padding-top: 2.25rem;

    .quote {
      width: 68px;
      height: 68px;
      background-color: #f8f8f8;
      font-size: 25px;
      @include border-radius(100%);
      line-height: 68px;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      position: absolute;
      top: -34px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.NETREVIEWS_PRODUCT_STARS{
  height: 25px;
}

.card-image {
  position: relative;
  max-width: 470px;
  margin-bottom: 25px;
  border: none;
  font-family: Dosis;

  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $dune-color-dark-blue);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  h3 {
    bottom: 31px;
    z-index: 1;
  }
  @include media-breakpoint-up(lg) {
    &:after {
      content: none;
    }
    &:hover {
      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $dune-color-dark-blue);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.card-member {
  .card-img-top {
    min-height: 0.01px; /* FIX IE 11*/
  }
  .card-img-top:nth-child(2) {
    display: none;
  }

  &:hover {
    .card-img-top:first-child {
      display: none;
    }


    .card-img-top:nth-child(2) {
      display: block;
    }
  }

  .team-member {
    color: $dune-color-corail;
    font-weight: bold;
  }

  .team-position {
    text-transform: uppercase;
    font-weight: bold;
    @include fontSize($font-size-xxs)
  }

  .team-location {
    font-style: italic;
    @include fontSize($font-size-xxs)
  }
}

.card-option {
  background-color: transparent;

  .card-body {
    font-family: OpenSans;
    font-weight: bold;
    background-color: transparent;

    .title-main {
      color: $dune-color-corail;
    }

    .title-sub {
      @include fontSize($font-size-xs);
      line-height: 16px;
      font-weight: bold;

    }

    .card-text {
      @include fontSize($font-size-xxs);
    }

    .price {
      @include fontSize($font-size-xxs);
    }

    .card-form {
      @include fontSize($font-size-xxxs);
      margin-top: 5px;
      text-transform: uppercase;
    }
  }
}

.card-cabin {
  max-width: 500px;

  .card-title {
    color: $dune-color-dark-blue;
  }

  ul.card-text {
    list-style: none;
    padding-left: 0;

    & > li {
      @include fontSize($font-size-xs);
      color: $dune-color-corail;

      .title {
        color: $dune-color-dark-blue;
      }
    }
  }
}

.card-actu {
  padding: 45px 30px;
  margin-bottom: 25px;
  max-width: 470px;
  height: 264px;

  h3 {
    font-family: Raleway;
  }

  p {
    font-family: Raleway;
    @include fontSize($font-size-sm)
  }
  .btn-dune-light-md{
    font-family: Raleway;
  }
  @include media-breakpoint-up(lg) {
    padding: 45px 80px;
  }
}

.card-pays {
  bottom: 0;
  display: flex;
  width: 100%;
  left: 0;
  justify-content: space-around;
  position: absolute;
  background-color: white;
  padding: 15px;
  align-items: center;
  height: 70px;

  p {
    @include fontSize($font-size-xxs);
    text-transform: uppercase;
    font-family: Dosis;
    font-weight: normal;
    line-height: 25px;
    margin-bottom: 0;

    .price {
      color: $dune-color-corail;
      font-weight: bold;
      @include fontSize(30px)
    }
  }

  @include media-breakpoint-up(md) {
    bottom: 30px;
    left: 30px;
    width: auto;
    height: auto;
    display: block;
    p {
      margin-bottom: 1rem;
    }
  }
}

/*========================================
End Cards
========================================*/

/*========================================
Tag for card
========================================*/

.tag-white {
  display: inline-block;
  background-color: $dune-color-white;
  color: $dune-color-dark-blue;
  line-height: 10px;
  border: 1px solid $dune-color-dark-blue;
  text-transform: uppercase;
  font-weight: bold;
  padding: 9px 10px;
  @include fontSize($font-size-xxxs);
  @include border-radius(5px);

  img, svg {
    fill: $dune-color-dark-blue;
    margin-right: 15px;
    height: 15px;
    width: 13px;
  }
}

.tag-blue {
  display: inline-block;
  background-color: $dune-color-dark-blue;
  color: $dune-color-white;
  line-height: 10px;
  border: 1px solid $dune-color-white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 9px 10px;
  @include fontSize($font-size-xxxs);
  @include border-radius(5px);

  img, svg {
    fill: $dune-color-white;
    margin-right: 15px;
    height: 15px;
    width: 13px;
  }
}

.tag-top {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1;
}

.tag-bottom {
  position: absolute;
  bottom: 14px;
  right: 14px;
  z-index: 1;
}

.group-tag-top {
  position: absolute;
  top: 14px;
  right: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  > div {
    flex: 0 1 auto;
  }
}

