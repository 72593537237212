table.table {
  border-spacing: 0;
  font-size: $font-size-xxs;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid $dune-color-corail !important;
  border-collapse: collapse;

  tr {
    &.full {
      background-color: rgba(242,112,89,0.4) !important;

      .label__check {
        display: none!important;
      }
    }

    &:first-child {
      th {
        border-top: 2px solid $dune-color-corail
      }

      th:first-child {
        border-top-left-radius: 6px;
      }

      th:last-child {
        border-top-right-radius: 6px;
        border-right: 2px solid $dune-color-corail;
      }
    }

    &:last-child {
      td:first-child {
        border-bottom-left-radius: 6px;
      }

      td {
        border-bottom: 2px solid $dune-color-corail;
      }

      td:last-child {
        border-bottom-right-radius: 6px;
      }
    }

    td {
      border: 2px solid $dune-color-corail;
      padding: 5px;
    }

    th:first-child,
    td:first-child {
      border-left: 2px solid $dune-color-corail;
    }

    td:last-child {
      border-right: 2px solid $dune-color-corail;
    }

    td {
      vertical-align: middle !important;
      border-top: 0 !important;
      a {
        color: $dune-color-dark-blue;
      }
      .tag-blue-xs {
        background-color: $dune-color-dark-blue;
        padding: 1px 5px;
        color: $dune-color-white;
        @include border-radius(5px);
        display: inline-block;
        text-align: center;
      }
      .price {
        display: block;
        @include fontSize($font-size-xlg)
      }
      .collapse, .collapsing {
        .card {
          border: none;
          padding: 20px 0 20px 0;
          background-color: transparent;

          a {
            text-decoration: underline;
            color: $dune-color-dark-blue;
            line-height: 30px;
          }
        }
      }

      .checkbox-booking {
        margin-bottom: 0;

        .label__check {
          @include border-radius(50%);
          border: 5px solid $dune-color-grey;
          background: $dune-color-grey;
          vertical-align: middle;
          margin-right: 20px;
          width: 2em;
          height: 2em;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: border .3s ease;

          svg, i {
            color: $dune-color-grey;
          }

          &:hover {
            border: 5px solid $dune-color-grey;
          }
        }

        .label__checkbox[aria-expanded="true"] + .label__text .label__check {
          animation: check .5s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;

          .icon {
            transform: scale(0);
            color: $dune-color-corail;
            animation: icon .3s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
          }
        }

        .label__checkbox {
          display: none;
        }

        @keyframes icon {
          from {
            opacity: 0;
            transform: scale(0.3);
          }
          to {
            opacity: 1;
            transform: scale(1)
          }
        }

        @keyframes check {
          0% {
            width: 1.5em;
            height: 1.5em;
            border-width: 5px;
          }
          10% {
            width: 1.5em;
            height: 1.5em;
            opacity: 0.1;
            background: rgba(0, 0, 0, 0.2);
            border-width: 15px;
          }
          12% {
            width: 1.5em;
            height: 1.5em;
            opacity: 0.4;
            background: rgba(0, 0, 0, 0.1);
            border-width: 0;
          }
          50% {
            width: 2em;
            height: 2em;
            background: $dune-color-dark-blue;
            border: 0;
            opacity: 0.6;
          }
          100% {
            width: 2em;
            height: 2em;
            background: $dune-color-dark-blue;
            border: 0;
            opacity: 1;
          }
        }
      }

      ul {
        list-style: none;
        text-align: left;
        li {
          text-transform: uppercase;
          img, svg {
            height: 32px;
          }
        }
      }
    }
  }

  thead th {
    border: 2px solid $dune-color-corail!important;
    text-align: center;
  }
}

table.table-schedule {
  border-collapse: collapse;
  tbody > tr:nth-child(2n+0) {
    td {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  tbody > tr:nth-child(4n+0), tbody > tr:nth-child(4n+3) {
    background-color: $dune-color-grey-light;
  }
  td {
    border: 0;
  }
  th {
    border-left: none!important;
    border-right: none!important;
    border-top: none!important;
  }


  @include media-breakpoint-up(md) {
    border: 2px solid $dune-color-corail !important;
    th,
    td {
      border: 2px solid $dune-color-corail !important;
    }

    thead {
      th,
      td {
        border-bottom-width: 2 * 1px;
      }
    }
  }
}