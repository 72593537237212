#region {
    .nav__wrapper {
        .navigation-menu {
            color: #000B1D;
            background: white;
        }
    }
    .expedition-nav svg {
        color: #D73F62;
    }
}

.region {
    &Header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin: 0 50px 80px;
        border-radius: 20px;
        overflow: hidden;
        min-height: 530px;
        padding: 0;
        position: relative;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
        &Name {
            font-family: 'Abril Fatface';
            font-size: 98px;
            line-height: 98px;
            text-transform: none;
            color: white;
            margin: 0 0 25px;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
        }
        &Title {
            font-weight: 700;
            font-size: 32px;
            line-height: 32px;
            color: white;
            text-transform: none;
            margin: 0 0 54px;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
        }
    }
    &Details {
        padding: 0;
        &Item {
            display: flex;
            align-items: center;
            column-gap: 40px;
            margin-bottom: 30px;
            position: relative;
            &.first {
                padding-right: 20px;
                &:after {
                    content: "";
                    width: 360px;
                    height: 90px;
                    background: url(../../../images/new/line-pointer.png) no-repeat;
                    background-size: cover;
                    display: block;
                    margin: 15px 0;
                    position: absolute;
                    z-index: 2;
                    right: 30%;
                    bottom: -60px;
                }
                .regionDetailsItemPicture {
                    z-index: 3;
                }
            }
            &.second {
                justify-content: flex-end;
                padding-left: 20px;
                .regionDetailsItemPicture {
                    border-radius: 20px 0 0 20px;
                }
            }
            &Picture {
                min-height: 340px;
                height: 100%;
                position: relative;
                border-radius: 0 20px 20px 0;
                overflow: hidden;
                width: 50%;
                max-width: 50%;
                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
            }
            &Content {
                &Title {
                    text-transform: none;
                    font-size: 32px;
                    line-height: 32px;
                    font-weight: 700;
                    &:after {
                        content: '';
                        width: 87px;
                        height: 12px;
                        background: url(../../../images/new/title-deco.png) no-repeat;
                        background-size: contain;
                        display: block;
                        margin: 15px 0;
                    }
                }
                &Text {
                    max-width: 515px;
                    strong {
                        font-weight: 700;
                    }
                    a {
                        color: $cprimary;
                        text-decoration: none;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .regionHeader {
        margin: 0 20px 80px;
        min-height: 440px;
        &Name {
            font-size: 75px;
            line-height: 75px;
        }
        &Title {
            font-size: 28px;
            line-height: 28px;
        }
    }
}

@media (max-width: 767px) {
    .region {
        &Header {
            min-height: 80vw;
            margin-bottom: 40px;
            &Name {
                font-size: 55px;
                margin-bottom: 10px;
            }
            &Title {
                font-size: 22px;
                line-height: 22px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
        &DetailsItem {
            flex-direction: column-reverse;
            row-gap: 20px;
            &.first {
                padding-right: 0;
                &::after {
                    display: none
                }
            }
            &.second {
                padding-left: 0;
                flex-direction: column;
                .regionDetailsItemPicture {
                    border-radius: 20px;
                }
            }
            &Picture {
                width: calc(100% - 40px);
                max-width: calc(100% - 40px);
                margin: 0 auto;
                border-radius: 20px;
            }
            &Content {
                max-width: calc(100% - 40px);
                &Text {
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .destinations {
        &Infos {
            flex-direction: column;
        }
        &Favorite::before {
            font-size: 60px;
            left: 10px;
        }
    }
    .regionHeaderTitle {
        padding: 0 20px;
    }
}