ul.check-style {
  font-family: OpenSans;
  list-style: none;

  li {
    margin-bottom: 10px;
    &:before {
      font-family: "Font Awesome";
      content: "\f00c";
      color: #f8f9fa;
      background-color: coral;
      @include border-radius(50%);
      text-align: center;
      padding: 2px;
      font-size: 12px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}