#boat, #accommodation {
  #cabin {
    .carousel.dune-carousel {
      & > .carousel-inner {
        justify-content: space-between;

        h4 {
          position: relative;
          top: initial;
        }

        & > div {
        }
      }
    }
  }

  #equipment {
    ul {
      font-family: OpenSans;
      padding-left: 20px;

      li {
        color: $dune-color-dark-blue;

        .title {
          color: $dune-color-corail;
        }
      }
    }
  }
  #plan-boat {
    .img-map {
      border: 2px solid $dune-color-corail;
    }
  }
}