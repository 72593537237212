.destinations {
    position: relative;
    background: $ctertiary;
    padding-bottom: 0;
    &::before {
        content: "";
        position: absolute;
        background-size: contain;
        background-repeat: repeat-x;
        width: 100%;
        background-image: url(../../../images/new/destinations-top-separator.png);
        top: -30px;
        height: 66px;
    }
    & + .testimonials {
        margin-top: 10px;
        &::before {
            top: -30px;
            z-index: 0;
        }
    }
    .nav {
        &-tabs {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            column-gap: 40px;
            position: sticky;
            top: 114px;
            background: $ctertiary;
            z-index: 10;
        }
        &-link {
            font-size: 18px;
            font-weight: 600;
            color: white;
            padding: 15px 20px;
            margin: 0;
            border: 0;
            &.active {
                background: none;
                color: $csecondary;
                position: relative;
                border: 0;
                &:after {
                    content: "";
                    background: $csecondary;
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    bottom: -9px;
                    left: 0;
                }
            }
            &:hover {
                border: 0;
                color: $csecondary;
            }
        }
        &-item {
            padding-bottom: 4px;
        }
    }
    .tab {
        &-content {
            background: url(../../../images/new/bg-dest.png) white no-repeat;
            background-size: 1128px;
            background-position: 80px 100%;
            border-radius: 50px 50px 0 0;
            position: relative;
            padding-bottom: 140px;
        }
    }
    &Title {
        color: white;
        text-transform: none;
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 8px;
    }
    &Intro {
        color: white;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 20px;
    }
    &Infos {
        display: flex;
        align-items: center;
        gap: 50px;
        padding: 40px 115px 0;
        &.reverse {
            flex-direction: row-reverse;
        }
        &Picture {
            border-radius: 20px;
            overflow: hidden;
            flex: 1.2;
            max-height: 340px;
            img {
                object-fit: cover;
                object-position: center;
            }
        }
        &Content {
            flex: 1;
            &Title {
                font-family: "Abril Fatface";
                text-transform: none;
                font-size: 32px;
                line-height: 32px;
                margin-bottom: 20px;
            }
            &Details {
                margin-bottom: 20px;
            }
            &Advantages {
                display: flex;
                flex-wrap: wrap;
                margin: 0 0 23px 0;
                padding: 0;
                gap: 10px 25px;
                &Item {
                    max-width: 68px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    &Picto {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background: $csecondaryLight;
                        margin-bottom: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            max-width: 30px;
                            max-height: 30px;
                            height: auto;
                        }
                    }
                    &Text {
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }
        }
    }
    &Favorite {
        position: relative;
        padding-top: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &::before {
            content: attr(data-name);
            position: absolute;
            top: 120px;
            left: 40px;
            height: 60px;
            color: $cgreyLight;
            font-family: "Abril Fatface";
            font-size: 200px;
            z-index: 0;
        }
        &::after {
            content: "";
            position: absolute;
            background-size: contain;
            background-repeat: repeat-x;
            width: 100%;
            background-image: url(../../../images/new/destinations-bottom-separator.png);
            bottom: -30px;
            left: 0;
            height: 60px;
            z-index: 1;
        }
        &Title {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            column-gap: 8px;
            position: relative;
            z-index: 1;
            margin-bottom: 8px;
            &Picto {
                width: 25px;
                max-width: 25px;
            }
            &Label {
                font-weight: 700;
                font-size: 32px;
                line-height: 32px;
                text-transform: none;
            }
        }
        &Intro {
            position: relative;
            z-index: 1;
            text-align: center;
            font-size: 14px;
            margin-bottom: 24px;
        }
        &Slider {
            position: relative;
            overflow: hidden;
            width: 100%;
            z-index: 1;
            padding: 0 105px 70px;
            visibility: hidden;
            &.slick-initialized {
                visibility: visible;
            }
            &::before {
                content: "";
                position: absolute;
                background-size: cover;
                width: 100%;
                height: 90%;
         //       background: $ctertiary;
                top: 80px;
                left: 0;
            }
            &::after {
                content: "";
                position: absolute;
                background-size: contain;
                background-repeat: repeat-x;
                width: 100%;
              //  background-image: url(../../../images/new/destinations-middle-separator.png);
                top: 40px;
                left: 0;
                height: 60px;
            }
            .slick {
                &-slide {
                    margin: 0 12px;
                    height: inherit !important;
                }
                &-list {
                    margin: 0 -12px;
                    position: relative;
                    z-index: 1;
                }
                &-track {
                    display: flex !important;
                }
                &-arrow {
                    width: 94px;
                    height: 94px;
                    border-radius: 50%;
                    background: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &::before {
                        content: '';
                        width: 18px;
                        height: 28px;
                        background-size: contain;
                    }
                    &:hover,
                    &:focus {
                        background: white;
                    }
                    &.slick-prev {
                        left: 40px;
                        &::before {
                            background-image: url(../../../images/new/prev.png);
                            margin-right: 20px;
                        }
                    }
                    &.slick-next {
                        right: 40px;
                        &::before {
                            background-image: url(../../../images/new/next.png);
                            width: 17px;
                            margin-left: 20px;
                        }
                    }
                }
            }
            &More {
                border-radius: 10px;
                overflow: hidden;
                height: 100%;
                background: url(../../../images/new/bg-product-more.png) no-repeat;
                background-size: cover;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 40px;
                &Title {
                    color: white;
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: none;
                    margin-bottom: 16px;
                }
                &Text {
                    color: white;
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 38px;
                }
                &Cta {
                    background: white;
                    color: $cprimary;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 15px 25px;
                    border-radius: 30px;
                    &:hover {
                        text-decoration: none;
                        background: $cprimary;
                        color: white !important;
                    }
                }
            }
            &Card {
                border-radius: 10px;
                overflow: hidden;
                height: 100%;
                display: block;
                &:hover {
                    text-decoration: none;
                }
                &Picture {
                    height: 256px;
                    width: 100%;
                    border-radius: 10px;
                    overflow: hidden;
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    position: relative;
                    z-index: 2;
                    &Type {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $csecondaryLight;
                        border-radius: 40px;
                        padding: 6px 15px;
                        column-gap: 8px;
                        &Picto {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                width: auto;
                                max-width: none;
                            }
                        }
                        &Label {
                            color: $csecondary;
                            font-weight: 600;
                        }
                    }
                    &Tags {
                        padding: 0;
                        margin: 0;
                        gap: 8px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        &Item {
                            background: $cprimaryLight;
                            border-radius: 40px;
                            padding: 6px 15px;
                            color: $cprimary;
                            font-weight: 600;
                        }
                    }
                }
                &Content {
                    background: white;
                    padding: 32px 16px 16px;
                    position: relative;
                    z-index: 1;
                    margin-top: -10px;
                    height: calc(100% - 246px);
                    &Name {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 24px;
                        margin-bottom: 4px;
                        color: #333;
                    }
                    &Note {
                        display: flex;
                        align-items: flex-end;
                        margin-bottom: 12px;
                        column-gap: 5px;
                        color: #333;
                        &Number {
                            font-size: 12px;
                            line-height: 12px;
                        }
                        &Label {
                            font-weight: 600;
                        }
                    }
                    &Date {
                        font-weight: 600;
                        font-size: 12px;
                        margin-bottom: 4px;
                        color: #333;
                    }
                    &Price {
                        font-size: 12px;
                        color: #333;
                        &Old {
                            text-decoration: line-through;
                            color: $cgrey2;
                        }
                        &New {
                            font-weight: 700;
                            font-size: 24px;
                            color: $cprimary;
                        }
                    }
                }
            }
        }
        &Cta {
            position: relative;
            z-index: 2;
            margin-top: -48px;
        }
    }
}

@media (max-width: 1199px) {
    .destinations {
        &::before {
            background-size: cover;
        }
        &Infos {
            padding: 40px 55px 0;
        }
        &Favorite {
            &::before {
                font-size: 150px;
            }
            &::after {
                background-size: cover;
            }
            &Slider {
                padding: 0 55px 70px;
                .slick-arrow {
                    width: 70px;
                    height: 70px;
                    &::before {
                        width: 15px !important;
                        height: 24px;
                    }
                    &.slick-prev {
                        left: 14px;
                    }
                    &.slick-next {
                        right: 14px;
                    }
                }
                &CardPictureTagsItem {
                    font-size: 14px;
                }
            }
        } 
        .nav-tabs {
            column-gap: 20px;
        }
    }
}

@media (max-width: 991px) {
    .destinations {
        .nav-tabs {
            top: 74px;
        }
        &Favorite {
            padding-top: 50px;
            &::before {
                font-size: 120px;
            }
            &SliderCard {
                &Picture {
                    height: 266px;
                }
                &Content {
                    height: calc(100% - 246px);
                }
            }
        }
        &Infos {
            gap: 30px;
        }
    }
}

@media (max-width: 767px) {
    .destinations  {
        &Infos {
            padding: 30px 30px 0;
            flex-direction: column;
            &.reverse {
                flex-direction: column-reverse;
            }
        }
        &Favorite {
            &::before {
                font-size: 90px;
            }
        }
        .nav {
            &-link {
                padding: 10px;
                font-size: 15px;
            }
            &-tabs {
                gap: 0 10px;
            }
        }
    }
}

@media (max-width: 575px) {
    .destinations  {
        .tab-content {
            border-radius: 20px 20px 0 0;
            padding-bottom: 80px;
        }
        .nav {
            &-link {
                padding: 10px 5px;
                font-size: 14px;
            }
        }
        &Intro {
            padding: 0 20px;
        }
        &Favorite {
            &::before {
                font-size: 60px;
                left: 10px;
            }
            &Intro {
                padding: 0 20px;
            }
            &Title {
                &Label {
                    font-size: 28px;
                }
            }
        }
    }
}