nav {
  .breadcrumb {
    font-size: $font-size-xxxs;
    background-color: transparent;

    li.breadcrumb-item {
      & + .breadcrumb-item::before {
        content: "|";
      }

      &.active {
        color: $dune-color-dark-blue;
        text-transform: uppercase;
      }

      a {
        color: $dune-color-dark-blue;
        text-transform: uppercase;
      }
    }
  }
}