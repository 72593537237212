.modal-backdrop.show {
  background-color: #098d78;
  opacity: 0.8;
}

.modal {
  text-align: center;

  .close-search {
    @extend .close;
    width: 33px;
    margin-top: 20px;
    margin-right: 33px;
  }

  .modal-header {
    border: 0;
    padding: 1rem 1rem 0 1rem;

    .close {
      font-size: 30px;
      font-weight: 100;
    }
  }

  .modal-body {
    padding: 0 1rem 1rem 1rem;

    img, svg {
      font-size: 76px;
      color: $dune-color-corail;
      margin-bottom: 5px;
    }

    .title {
      color: $dune-color-corail;
      text-transform: uppercase;
      font-size: 30px;
    }

    p {
      text-transform: uppercase;
      font-family: Dosis;
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  .modal-footer {
    border: 0;
    justify-content: center;
  }

  .modal-gallery {
    max-width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 70%;
    }
  }

  .modal-video {
    max-width: 100%;
    .modal-body {
      padding: 0;
    }
    @include media-breakpoint-up(md) {

    }

    .modal-content {
      background-color: transparent;
      border: none;
    }
    @include media-breakpoint-up(md) {
      max-width: 70%;
      .modal-body {
        padding: 0 1rem 1rem 1rem;
      }
    }
  }

  &.ekko-lightbox {
    .modal-body {
      padding: 0 !important;
    }

    .ekko-lightbox-nav-overlay {
      a {
        opacity: 0.5;
        color: $dune-color-white;
        @include fontSize(50px)
      }

      a:hover {
        opacity: 1;
      }

      a:first-child {
        text-align: left;
      }

      a:last-child {
        text-align: right;
      }
    }
  }
}
